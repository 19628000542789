import * as constant from "domain/constants/coupon.constant";
import * as types from "domain/types/coupon.type";

export function getCouponById(couponId: string, cb: any): types.CouponSagaTypes{
  return {
    type: constant.COUPON_SAGA_GET_BY_ID,
    payload: { couponId, cb }
  }
}

export function restCoupon(data: any): types.CouponSagaTypes {
  return {
    type: constant.COUPON_SAGA_RESTART,
    payload: { data }
  }
}

export function deleteCoupon(couponId: string): types.CouponSagaTypes {
  return {
    type: constant.COUPON_SAGA_DELETE,
    payload: { couponId }
  }
}

export function setCoupon(coupon: any): types.CouponReduceTypes{
  return {
    type: constant.COUPON_REDUCE_SET,
    payload: { coupon }
  }
}