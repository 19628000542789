import { combineReducers } from 'redux'
import * as constants from "domain/constants/auth.constant";
import * as types from "domain/types/auth.type";
import { IStatusResponse } from 'domain/types/status.type';
import { load } from 'redux-localstorage-simple';

const savedReduce: any = load();

const initialState = {
  isAuthenticated: (savedReduce && savedReduce.auth) ? savedReduce.auth.isAuthenticated : false,
  restore: null,
  windows: {
    auth: false,
    checked: false,
    checkedRestore: false,
    completed: false,
    register: false,
    restore: false,
    secretShopper: false,
  },
  request: {
    loading: false,
    error: null
  }
};

export function isAuthenticated(state = initialState.isAuthenticated, action: types.AuthReduceTypes): boolean {
  switch (action.type) {
    case constants.AUTH_REDUCE_SET_AUTH:
      return action.payload.isAuthenticated;
    default:
      return state;
  }
}

export function restore(state = initialState.restore, action: types.AuthReduceTypes): string | null {
  switch (action.type) {
    case constants.AUTH_REDUCE_SET_DATA_RESTORE:
      return action.payload.restore;
    default:
      return state;
  }
}

export function windows(state = initialState.windows, action: types.AuthReduceTypes): types.IAuthWindows {
  switch (action.type) {
    case constants.AUTH_REDUCE_IS_SHOW_WINDOW:
      const { window, isShow } = action.payload;
      return {
        ...state,
        [window]: isShow
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.AuthReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.AUTH_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    case constants.AUTH_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export default combineReducers({
  isAuthenticated,
  restore,
  windows,
  request
})