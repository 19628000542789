import React from "react";
import {
  AuthWindow,
  RegWindow,
  RestoreWindow,
  CompletedWindow,
  CheckCodeWindow,
  CheckCodeRestoreWindow,
  SecretShopperWindow
} from "./components";

const AuthWindows: React.FC = () => {

  return (
    <React.Fragment>
      <AuthWindow />
      <RegWindow />
      <RestoreWindow />
      <CompletedWindow />
      <CheckCodeWindow />
      <CheckCodeRestoreWindow />
      <SecretShopperWindow />
    </React.Fragment>
  );
}

export default AuthWindows;
