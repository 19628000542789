import React from "react";

import styles from "../styles.module.css";


export const About: React.FC = () => {
  return (
    <div id="aboutUs" className={styles['about'] + ' block'}>
      <h2 className={styles['title']} >О нас</h2>

      <div className='p-col-12 p-d-flex p-p-0 p-flex-wrap' >
        <div className='p-col-12 p-xl-8 p-p-0'>
          <div className={styles['text'] + ' p-col-12 p-p-0'}>
            <p className="p-mt-xl-6">
              <b><a href="/" className={styles['ancor']}>"3RAZA: Путеводитель проверенных заведений"</a></b> создан для тех, кто хочет найти лучшие заведения и быть уверенным в качестве предоставляемых услуг.
              Здесь собраны только проверенные места, где ценят каждого посетителя и стремятся обеспечить им великолепное обслуживание.
            </p>

            <p className="">
              На нашем сайте вы найдете <a href="/#/main/restaurants" className={styles['ancor']}>подборку лучших ресторанов</a>, куда сходить и покушать в вашем городе. 
              Мы также предлагаем информацию о лучших кальянных, smoke lounge и кафе, где вы сможете насладиться уютной атмосферой и вкусными блюдами.
            </p>

            <p className="p-d-none p-d-md-block">
            Одним из ключевых элементов нашего сервиса является функция <a href="https://landing.3raza.ru/" target="_blank" rel="noopener noreferrer" className={styles['ancor']}><em>"Тайный покупатель"</em></a>,
            которая позволяет нашим пользователям получить дополнительные сведения о ресторанах/кофейнях/центр паровых коктейлей, прежде чем посетить их.
            А зарегистрированные пользователи нашего сайта, 
            могут сами стать <em>"тайным покупателем"</em> и принять непосредственное участие в проверке заведений и получать от нас хороший кэшбэк. 
            <br/>
            Зарегистрироваться можно <a href="/#/main/registration" className={styles['ancor']}>тут.</a>
            </p>

            
          </div>
          
          <div className="p-col-12 p-p-0 p-d-flex p-jc-between ">
            <div className={styles['text'] + ' p-col-6 p-xl-12 p-p-0'}>
              <p className="p-d-none p-d-md-block p-mt-md-0">
              Наша команда постоянно работает над тем, чтобы обновлять данные о заведениях и предоставлять нашим пользователям только самую актуальную и достоверную информацию.
              </p>

              <p>
              Так что если вы ищете хорошее и превосходное место для посещения в вашем городе, обратитесь к <em><a href="/" className={styles['ancor']}><strong>3RAZA</strong></a> - вашему надежному путеводителю по проверенным заведениям.</em>
              </p>
            </div>

            <div className="p-d-flex p-d-xl-none p-md-4 p-p-0 p-jc-md-center">
              <img 
                src={process.env.PUBLIC_URL + "/images/girl_mobile.png"}
                alt='Путеводитель 3RAZA'
                className={styles['image']}
              />
            </div>
          </div>
        </div>

        <div className="p-d-none p-d-xl-flex p-md-4 p-p-0 p-jc-md-center">
          <img 
            src={process.env.PUBLIC_URL + "/images/girl_mobile.png"}
            alt='Путеводитель 3RAZA'
            className={styles['image']}
          />
        </div>
      </div>
      
    </div>
  );
}


export default About;