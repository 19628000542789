import { put, fork, takeEvery, call } from 'redux-saga/effects'
import * as norr from 'domain/actions/norr.action';
import * as constants from 'domain/constants/norr.constant';
import { NorrController } from 'domain/controllers/Response.controller';
import ApiController from 'domain/controllers/Api.controller';
import { Result } from 'domain/types/other.type';

const api = new ApiController();
const norrController = new NorrController();

export async function sendErrorFetch(msg: string): Promise<Result<void>> {
  return await api.post(`/mail/msg/error`, { msg })
}

const wait = async () => {
  await setTimeout(function() { console.log('')  }, 300)
  return;
}

export function* showError(action: any): any{
  yield put(norr.error(action.payload.msg))
  yield call(wait)
  yield put(norr.clear())
}

export function* sendError(action: any): any{
  const response = yield call(sendErrorFetch, action.payload.errorMsg )

  yield call(norrController.processing, response, function *(){
    yield put(norr.showCriticalError(false))
  }, 'Письмо успешно отправлено')
}

export function* watch() {
  yield takeEvery(constants.NORR_SAGA_SHOW_ERROR, showError)
  yield takeEvery(constants.NORR_SAGA_SEND_ERROR_MSG, sendError)
}

export default function* sagas() {
  yield fork(watch)
}