import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";
import { ContentLayout, BtnLayout } from "./layouts";

import { Dialog } from "primereact/dialog";

import styles from "../../style.module.css";

export const CompletedWindow: React.FC = (props: any) => {
  const { isShow, showWindow } = props;

  return (
    <Dialog
      className={styles.authWindow + " " + styles.completedWindow}
      header="Регистрация"
      visible={isShow}
      onHide={() => showWindow('completed', false)}
      modal
      draggable={false}
    >
      <div className="p-col-12 p-p-0">
        <ContentLayout />
        <BtnLayout onClick={() => {
          showWindow('completed', false)
          showWindow('auth', true)
        }} />
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  isShow: state.auth.windows.completed
})

export default connect(mapStateToProps, {showWindow})(CompletedWindow);
