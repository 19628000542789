import { combineReducers } from 'redux'
import { RESTAURANT_REDUCE_SET__STOCKS_ALL_IN_MAIN } from 'domain/constants/restaurant.constant';
import { SECRET_SHOPPER_REDUCE_SET__TASKS_ALL_IN_MAIN } from 'domain/constants/secretShopper.constant';
import { MAIN_SET__VIEWS } from 'domain/actions/main.action';
// import * as types from "domain/types/secretShopper.type";



const initialState = {
  tasks: {
    count: 0,
    rows: []
  },
  stocks: {
    count: 0,
    rows: []
  },
  views: [
    // { type: 'news', data: 
    //   { id: 2, 
    //     name: 'Масленица. Блин или Оладик?', 
    //     description: 'Каждый раз когда приходишь в кальянную, первым же вопросом возникает  “Что сегодня покушать, вот в чем вопрос!?!?', 
    //     image: 'https://3raza.ru/api/files/public/logotypes/1709927104173-821383460.jpg' 
    //   } 
    // },
  ]
}

export function tasks(state = initialState.tasks, action: any): any {
  switch (action.type) {
    case SECRET_SHOPPER_REDUCE_SET__TASKS_ALL_IN_MAIN:
      return action.payload.tasks;
    default:
      return state;
  }
}

export function stocks(state = initialState.stocks, action: any): any {
  switch (action.type) {
    case RESTAURANT_REDUCE_SET__STOCKS_ALL_IN_MAIN:
      return action.payload.stocks;
    default:
      return state;
  }
}

export function views(state = initialState.views, action: any): any {
  switch (action.type) {
    case MAIN_SET__VIEWS:
      return action.payload.views;
    default:
      return state;
  }
}

export default combineReducers({
  tasks,
  stocks,
  views
})