import React from "react";
import { logOut } from "domain/actions/auth.action";

import { connect } from "react-redux";
import styles from "../../style.module.css";
import { isHTTPS } from "utils/regexps";

interface IProps {
  onShowBurger?: () => void | undefined;
}

export const MobileMenuComponent: React.FC<IProps> = (props: any) => {
  const { user } = props;

  if(!user) return <></>

  return (
    <div className="mobile-menu-auth p-col-12 p-p-0 p-d-flex p-flex-column">
      <img 
        className={styles.mobile_menu_avatar}
        src={`${isHTTPS(user?.avatar) ? user.avatar : '/api/files/' + user?.avatar}`}
        alt={`${user?.name}`}
        onError={(e: any) => e.target.src = `${process.env.PUBLIC_URL}/images/avatar_default.png` }
      />
      <div className={styles.mobile_menu_value + " p-pt-3 p-pl-2"}>{user?.name}</div>
      <div className={styles.mobile_menu_value}>{user?.phone}</div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.account.account,
});

export default connect(mapStateToProps, {logOut})(MobileMenuComponent);
