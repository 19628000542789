import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { logOut } from "domain/actions/auth.action";
import UserButton from "./user.button";
import { connect } from "react-redux";
import styles from "../../style.module.css";
import { useAuth } from "routes/config/ProvideAuth";

const location = window.location;
const hostname = location.hostname;
const protocol = location.protocol;

export const UserMenuComponent: React.FC<any> = (props: any) => {
  const { user, className = '' } = props;
  const auth = useAuth();
  const navigator = useNavigate();
  const menu = useRef<any>(null);

  const host = hostname === 'localhost' ? 'localhost:3080' : (hostname === '3raza.com' || hostname === '3raza.ru') ? "owners.3raza.com" : "demo.owners.3raza.com";
  const url = protocol + "//" + host;
  
  const userBtn = (event: any) => {
    if(null !== menu && null !== menu.current) {
      menu.current.toggle(event);
    }
  }

  const owners = [
    {
      label:'На главную',
      icon:'pi pi-fw pi-home',
      command: () => navigator("/"),
      disabled: false
    },
    {
      label:'Список заведений',
      icon:'pi pi-fw pi-list',
      command: () => navigator("/main/restaurants"),
      disabled: false
    },
    {
      label:'Панель администратора',
      icon:'pi pi-fw pi-user-edit',
      command: () => {
        window.open(`${url}`, '_blank');
      },
      disabled: false
    },
    { separator: true },
    {
      label: "Выйти",
      icon: "pi pi-fw pi-power-off",
      command: () => props.logOut(),
    },
  ]

  const clients = [
    {
      label:'На главную',
      icon:'pi pi-fw pi-home',
      command: () => navigator("/"),
      disabled: false
    },
    {
      label:'Список заведений',
      icon:'pi pi-fw pi-list',
      command: () => navigator("/main/restaurants"),
      disabled: false
    }
  ]

  const personal = [
    {
      label:'Мой профиль',
      icon:'pi pi-fw pi-user-edit',
      command: () => navigator("/private/account/personal")
    },
    {
      label:'Мои акции',
      icon:'pi pi-fw pi-dollar',
      command: () => navigator("/private/account/stock")
    },
    {
      label:'Мои отзывы',
      icon:'pi pi-fw pi-envelope',
      command: () => navigator("/private/account/reviews")
    },
    {
      label:'Избранное',
      icon:'pi pi-fw pi-heart',
      command: () => navigator("/private/account/favorites"),
    }
  ]

  let account = [
    {
      label:'Личный кабинет',
      icon:'pi pi-fw pi-calendar',
      disabled: !auth.isAuthenticated,
      items: personal
    }
  ] as any[];

  const clientSecretShopper = [
    {
      label:'Список заданий',
      icon:'pi pi-fw pi-calendar',
      command: () => navigator("/private/account/secret_shopper/shopper"),
    },
    {
      label:'Мои задания',
      icon:'pi pi-fw pi-user',
      command: () => navigator("/private/account/secret_shopper/tasks"),
    },
    {
      label:'Архив',
      icon:'pi pi-fw pi-briefcase',
      command: () => navigator("/private/account/secret_shopper/archive"),
    },
    {
      label:'Настройки',
      icon:'pi pi-fw pi-cog',
      command: () => navigator("/private/account/secret_shopper/setting"),
    }
  ];

  let mixMenu = [
    {
      label:'Тайный покупатель',
      icon:'pi pi-fw pi-calendar',
      disabled: !auth.isAuthenticated,
      items: user?.role === 'owner' || user?.role === 'manager' ? [] : clientSecretShopper
    },
    { separator: true },
    {
      label: "Выйти",
      icon: "pi pi-fw pi-power-off",
      command: () => props.logOut(),
    },
  ] as any[];

  if(user?.role === 'owner' || user?.role === 'manager') mixMenu = [...owners];
  else if(user?.role === 'client' ) mixMenu = [...clients, ...account, ...mixMenu];

  return (
    <div className={className}>
      <Menu
        model={mixMenu}
        popup
        ref={menu}
        id="popup_menu"
        style={{ marginTop: ".1rem" }}
      />
      <UserButton
        user={user}
        className={styles.btn_login}
        onClick={userBtn}
        aria-controls="popup_menu"
        aria-haspopup
      />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.account.account,
});

export default connect(mapStateToProps, {logOut})(UserMenuComponent);
