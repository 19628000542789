import React, { useEffect, useState } from "react";

import { ProgressBar } from "primereact/progressbar";
import { useLocation } from "react-router-dom";

interface IWrapperProps{
  timer?: number;
  children: React.ReactNode;
  progress?: boolean;
}

export const WrapperProgress: React.FC<IWrapperProps> = (props: any) => {
  const { timer = 1000, progress = false, children } = props;
  const [ showProgress, setShowProgress ] = useState(false)
  const location = useLocation()

  useEffect(() => { 
    setShowProgress(true)
    setTimeout(() => setShowProgress(false), timer)
  }, [setShowProgress, location.pathname, timer])

  return (
    <>
      {
        showProgress || progress
        ? (
          <>
          <ProgressBar mode="indeterminate" style={{ height: '3px' }}></ProgressBar>
          <div style={{height:'1000px'}}></div>
          </>
        )
        : children
      }
    </>
  );
}

