import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isInStandaloneMode, isIos, isMobile, isYandex } from 'utils/isPwa';


export const PwaInstallWin: React.FC = () => {
  const [ show, setShow ] = useState(false)
  const [cookies, setCookie] = useCookies();

  

  useEffect(()=>{
     // Checks if should display install popup notification:
    if(cookies.pwaiclose) return;
    if (isMobile.any() && isIos() && !isYandex() && !isInStandaloneMode()) {
      setShow(true);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const handleClose = () => {
    setCookie("pwaiclose", 'true', {expires: new Date(new Date().getTime() + 86400000 * 2)});
    setShow(false)
  }

  return (
    <Dialog
      visible={show}
      position={"bottom"}
      modal={false}
      dismissableMask
      contentClassName="pwa-windows"
      showHeader={false}
      onHide={handleClose}
    >
      <div style={{ fontSize: '13px' }}>
        <div className="p-col-12 p-p-0 p-d-flex p-flex-between">
          <div className='p-col-11 p-p-1'>Зайдите в браузер Safari, в поисковой строчке введите "3raza.ru".</div>
          <div className='p-col-1 p-p-0'> 
            <Button 
              icon="pi pi-times" 
              className="p-button-rounded p-button-outlined p-button-text" 
              style={{ color: 'white' }}
              onClick={handleClose}
            /> 
          </div>
        </div>
        <div className="p-col-12 p-pr-0 p-pl-0 p-d-flex">
          <img
            src={process.env.PUBLIC_URL + "/logo512.png"}
            alt="3Raza"
            width={52}
            height={52}
            style={{ borderRadius: '5px', marginRight: '.5rem' }}
          />
          <div>
            <span style={{ fontSize: '13px', fontWeight: 'bold' }} className="p-mb-1 p-d-block"> Добавьте ярлык 3Raza на экран 	&laquo;Домой&raquo;. </span>
            {/* <br /> */}
            Нажмите на иконку 
            <img
              src={process.env.PUBLIC_URL + "/images/upload-white-icon.png"}
              width={14}
              height={16}
              className='p-mr-1 p-ml-1'
              alt="logotype"
            /> 
            и в меню выберите 	&laquo;На экран &bdquo;Домой&rdquo;&raquo;
          </div>
          
        </div>
      </div>
    </Dialog>
  );
}
      
export default PwaInstallWin;