import React from "react";

import styles from "./styles.module.css";
import { Logotype, Separator } from "components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVk, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";


export const Footer: React.FC = (props: any) => {
  
  return (
    <footer className={styles['main']}>
      <Separator className={`${styles['separator']}`}/>
      <div className="p-mt-3">
        <Logotype className={styles['logotype']}/>

        <div className={styles['docs']}>
          <Link className={styles['docs_link']} to="/main/docs/userAgreement" target="_blank" rel="noopener noreferrer">Пользовательское соглашение</Link>
          <Link className={styles['docs_link']} to="/main/docs/cookies" target="_blank" rel="noopener noreferrer">Согласие на использование файлов Cookie</Link>
          {/* <Link className={styles['docs_link']} to="/main/docs/userAgreement" target="_blank" rel="noopener noreferrer">Политика конфиденциальности!</Link> */}
          <Link className={styles['docs_link']} to="/main/docs/dataProcessing" target="_blank" rel="noopener noreferrer">Политика обработки персональных данных</Link>
          {/* <Link className={styles['docs_link']} to="/main/docs/userAgreement" target="_blank" rel="noopener noreferrer">Карта сайта!</Link> */}
          {/* <Link className={styles['docs_link']} to="/main/docs/userAgreement" target="_blank" rel="noopener noreferrer">Обратная связь!</Link> */}
        </div>
      </div>
      
      <div className={`p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-my-md-3 p-px-md-2 ${styles['block_info']}`}>

        <div className="p-d-flex p-flex-column p-order-1 p-order-md-0 p-p-0 p-col-12 p-md-5 p-text-left">
          <span className={styles['info_text']}>3Raza - путеводитель проверенных заведений</span>
          <span className={styles['info_text']}>
            Использование контента 3Raza разрешено только  
            с предварительного согласия администрации сайта или правообладателей.
          </span>
        </div>

        <div className="p-order-0 p-d-flex p-flex-md-column p-jc-end p-jc-md-start p-mb-2 p-p-0 p-col-12 p-md-3">
          <a className={styles['soc_link']} href='https://vk.com/3raza' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faVk as IconProp} />
            <span className="p-d-none p-d-md-block">3raza</span>
          </a>
          <a className={styles['soc_link']} href='https://t.me/+xks27Pc6dCZiZDFi' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTelegram as IconProp} />
            <span className="p-d-none p-d-md-block">3raza-group</span>
          </a>
        </div>

      </div>

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-3 p-jc-between">
        <div className={styles['add_info']} >
          <div>
            <i className={styles['info_icon']}> <FontAwesomeIcon icon={faEnvelope as IconProp} /> </i>
            <u><Link to="/main/contact" className={styles['contact']}> Обратная связь </Link></u>
          </div>
          
          <u><Link to="/main/review" className={styles['review']}> Оставить отзыв </Link></u>
        </div>

        <div className=''>
          <span className={styles['copyright']}> &copy; 3raza, 2021-{new Date().getFullYear()} г, Все права защищены. </span>
        </div>
      </div>
    </footer>
  );
}


export default Footer;