import { combineReducers } from 'redux'
import * as constant from "domain/constants/restaurant.constant";
import * as types from "domain/types/restaurant.type";
import { IStatusResponse } from 'domain/types/status.type';
import { load } from 'redux-localstorage-simple';
import { ITask } from 'domain/types/secretShopper.type';

const savedReduce: any = load();

const initialState = {
  restaurants: [],
  count: 0,
  verifiedRestaurants: [],
  top: [],
  similar: [],
  current: null,
  tasks: [],
  pagination: {
    page: (savedReduce && savedReduce.restaurants && savedReduce.restaurants.pagination)
    ? savedReduce.restaurants.pagination.page : 1,
    pageSize: (savedReduce && savedReduce.restaurants && savedReduce.restaurants.pagination)
    ? savedReduce.restaurants.pagination.pageSize : 10,
  },
  filters: {
    categories: 
      (savedReduce && savedReduce.restaurants && savedReduce.restaurants.filters)
        ? savedReduce.restaurants.filters.categories : [],
    hashtags: 
      (savedReduce && savedReduce.restaurants && savedReduce.restaurants.filters)
        ? savedReduce.restaurants.filters.hashtags : [],
    adding: 
      (savedReduce && savedReduce.restaurants && savedReduce.restaurants.filters?.adding)
        ? savedReduce.restaurants.filters.adding : [],
    average: 
      (savedReduce && savedReduce.restaurants && savedReduce.restaurants.filters?.range)
        ? savedReduce.restaurants.filters.range : [0, 5000],
    special: 
      (savedReduce && savedReduce.restaurants && savedReduce.restaurants.filters?.special)
        ? savedReduce.restaurants.filters.special : [],
    like: "",
  },
  sorts: {
    geolocation: (savedReduce && savedReduce.restaurants && savedReduce.restaurants.sorts)
    ? savedReduce.restaurants.sorts.geolocation : undefined,
    rating: (savedReduce && savedReduce.restaurants && savedReduce.restaurants.sorts)
    ? savedReduce.restaurants.sorts.rating : undefined,
    averageReceipt: (savedReduce && savedReduce.restaurants && savedReduce.restaurants.sorts)
    ? savedReduce.restaurants.sorts.averageReceipt : undefined,
  },
  categories: [],
  hookah: [],
  kitchen: {
    categories: [],
    national: [],
    tags: []
  },
  windows: {
    establishment: false,
  },
  request: {
    loading: false,
    error: null
  }
}


export function restaurants(state = initialState.restaurants, action: types.RestaurantsReduceTypes): Array<types.IRestaurant>{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_ALL_CITY:
      return action.payload.data;
    default:
      return state;
  }
}

export function count(state = initialState.count, action: types.RestaurantsReduceTypes): number{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_ALL_COUNT:
      return action.payload.count;
    default:
      return state;
  }
}

export function verifiedRestaurants(state = initialState.verifiedRestaurants, action: types.RestaurantsReduceTypes): Array<types.IRestaurant>{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_VERIFIED:
      return action.payload.data;
    default:
      return state;
  }
}

export function top(state = initialState.top, action: types.RestaurantsReduceTypes): Array<types.IRestaurant>{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_TOP_CITY:
      return action.payload.data;
    default:
      return state;
  }
}

export function similar(state = initialState.similar, action: types.RestaurantsReduceTypes): Array<types.IRestaurant>{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_SIMILAR:
      return action.payload.data;
    default:
      return state;
  }
}

export function tasks(state = initialState.tasks, action: types.RestaurantsReduceTypes): Array<ITask>{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_TASKS_CURRENT:
      return action.payload.data;
    default:
      return state;
  }
}

export function pagination(state = initialState.pagination, action: types.RestaurantsReduceTypes): any{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_PAGINATION_PAGE:
      return {
        ...state,
        page: action.payload.page
      };
    default:
      return state;
  }
}

export function filters(state = initialState.filters, action: types.RestaurantsReduceTypes): any{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_FILTER_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, action.payload.categoryId]
      };
    case constant.RESTAURANTS_REDUCE_DELETE_FILTER_CATEGORIES:
      const categories = state.categories.slice();
      const indexCategory = categories.indexOf(action.payload.categoryId);
      if(indexCategory !== -1) categories.splice(indexCategory, 1);
      
      return {
        ...state,
        categories: categories
      };
    case constant.RESTAURANTS_REDUCE_CLEAR_FILTER_CATEGORIES:
      return {
        ...state,
        categories: []
      };
    case constant.RESTAURANTS_REDUCE_SET_FILTER_HASHTAGS:
      return {
        ...state,
        hashtags: [...state.hashtags, action.payload.hashtagId]
      };
    case constant.RESTAURANTS_REDUCE_DELETE_FILTER_HASHTAGS:
      const hashtags = state.hashtags.slice();
      const indexHashtag = hashtags.indexOf(action.payload.hashtagId);
      if(indexHashtag !== -1) hashtags.splice(indexHashtag, 1);
  
      return {
        ...state,
        hashtags: hashtags
      };
    case constant.RESTAURANTS_REDUCE_CLEAR_FILTER_HASHTAGS:
      return {
        ...state,
        hashtags: []
      };
    case constant.RESTAURANTS_REDUCE_SET_FILTER_ADDING:
      return {
        ...state,
        adding: [...state.adding, action.payload.addingId]
      };
    case constant.RESTAURANTS_REDUCE_DELETE_FILTER_ADDING:
      const adding = state.adding.slice();
      const indexAdding = adding.indexOf(action.payload.addingId);
      if(indexAdding !== -1) adding.splice(indexAdding, 1);
  
      return {
        ...state,
        adding: adding
      };
    case constant.RESTAURANTS_REDUCE_CLEAR_FILTER_ADDING:
      return {
        ...state,
        adding: []
      };
    
    case constant.RESTAURANTS_REDUCE_SET_FILTER_AVERAGE:
      return {
        ...state,
        average: action.payload.range
      };
    
    case constant.RESTAURANTS_REDUCE_CLEAR_FILTER_AVERAGE:
      return {
        ...state,
        average: [0, 5000]
      };

    case constant.RESTAURANTS_REDUCE_SET_FILTER_SPECIAL:
      return {
        ...state,
        special: [...state.special, action.payload.specialId]
      };
    case constant.RESTAURANTS_REDUCE_DELETE_FILTER_SPECIAL:
      const special = state.special.slice();
      const indexSpecial = special.indexOf(action.payload.specialId);
      if(indexSpecial !== -1) special.splice(indexSpecial, 1);
  
      return {
        ...state,
        special: special
      };
    case constant.RESTAURANTS_REDUCE_CLEAR_FILTER_SPECIAL:
      return {
        ...state,
        special: []
      };
    case constant.RESTAURANTS_REDUCE_SET_FILTER_LIKE:
      return {
        ...state,
        like: action.payload.like
      };
    default:
      return state;
  }
}

export function sorts(state = initialState.sorts, action: types.RestaurantsReduceTypes): any{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_SORTS:
      const { sort, indicator } = action.payload;
      
      if(sort === 'geolocation') return { geolocation: indicator, rating: undefined, averageReceipt: undefined };
      if(sort === 'rating') return { geolocation: undefined, rating: indicator, averageReceipt: undefined };
      if(sort === 'averageReceipt') return { geolocation: undefined, rating: undefined, averageReceipt: indicator };

      return {
        ...state
      };
    default:
      return state;
  }
}

export function categories(state = initialState.categories, action: types.RestaurantsReduceTypes): Array<types.ICategory>{
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_CATEGORIES:
      return action.payload.categories;
    default:
      return state;
  }
}

export function hookah(state = initialState.categories, action: types.RestaurantsReduceTypes): Array<types.ICategory> {
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_CATEGORIES_HOOKAH:
      return action.payload.categories;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.RestaurantsReduceTypes): types.IRestaurant | null {
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SELECT:
      return action.payload.restaurant;
    default:
      return state;
  }
}

export function kitchen(state = initialState.kitchen, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_SET_KITCHENS_DISHES:
      return {
        ...state,
        national: action.payload.kitchens
      };
    case constant.RESTAURANTS_REDUCE_SET_CATEGORIES_DISHES:
      return {
        ...state,
        categories: action.payload.categories
      };
    case constant.RESTAURANTS_REDUCE_SET_TAGS_DISHES:
      return {
        ...state,
        tags: action.payload.tags
      };
    default:
      return state;
  }
}

export function windows(state = initialState.windows, action: types.RestaurantsReduceTypes): types.IRestaurantWindows {
  switch (action.type) {
    case constant.RESTAURANTS_REDUCE_IS_SHOW_WINDOW:
      const { window, isShow } = action.payload;
      return {
        ...state,
        [window]: isShow
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.RestaurantsReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.RESTAURANTS_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    case constant.RESTAURANTS_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export function getRestaurants(state: { restaurants: {restaurants: Array<types.IRestaurant>}; }) {
  console.log(state)
  return state.restaurants.restaurants
}

export function getTopRestaurants(state: { restaurants: {top: Array<types.IRestaurant>}; }) {
  return state.restaurants.top
}

export function getCurrentRestaurant(state: { restaurants: {current: types.IRestaurant | null} ; }){
  return state?.restaurants.current
}

export function getErrorRestaurants(state: { restaurants: {request: types.RestaurantsReduceTypes} ; }){
  return state.restaurants.request
}

export function getFilterRestaurants(state: { restaurants: {filters: types.RestaurantsReduceTypes} ; }){
  return state.restaurants.filters
}

export function getSortsRestaurants(state: { restaurants: {sorts: types.RestaurantsReduceTypes} ; }){
  return state.restaurants.sorts
}

export function getPaginationRestaurants(state: { restaurants: {pagination: types.RestaurantsReduceTypes} ; }){
  return state.restaurants.pagination
}

export default combineReducers({
  restaurants,
  count,
  verifiedRestaurants,
  top,
  similar,
  current,
  tasks,
  pagination,
  filters,
  sorts,
  kitchen,
  hookah,
  categories,
  windows,
  request
})