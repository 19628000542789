import { combineReducers } from 'redux'
import * as constant from "domain/constants/reviews.constant";
import * as types from "domain/types/reviews.type";
import { IStatusResponse } from 'domain/types/status.type';

const initialState = {
  data: [],
  request: {
    loading: false,
    error: null
  }
}


export function data(state = initialState.data, action: types.ReviewsReduceTypes): types.IReview[]{
  switch (action.type) {
    case constant.REVIEWS_REDUCE_SET_ALL:
      return action.payload.data;
    default:
      return state;
  }
}


export function request(state = initialState.request, action: types.ReviewsReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.REVIEWS_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export function getReviews(state: { reviews: {data: types.IReview[]}; }) {
  return state.reviews.data
}


export default combineReducers({
  data,
  request
})