import { showWindow } from "domain/actions/auth.action";
import React from "react";
import { connect } from "react-redux";

import styles from "../../../style.module.css";

interface IProps {
  isPage?: boolean;
  showPage?: (name: string) => void;
}

const FooterLayout: React.FC<IProps> = (props: any) => {
  const { showWindow, isPage = false, showPage = () => {} } = props;

  const handleClick = () => {
    if(isPage) return showPage('auth')
    showWindow("register", false);
    showWindow("auth", true);
  }

  return (
    <div className="p-col-12 p-p-0 p-text-center" >
      <span className={styles.footer_form}>
        У вас уже есть учетной запись? <b onClick={handleClick} className="cursor-pointer p-ml-2"> Войти</b>
      </span>
    </div>
  );
}

export default connect(null, {showWindow})(FooterLayout);
