import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { 
  clearFilterCategories, 
  setFilterCategories, 
  setSorts, 
} from "domain/actions/restaurant.action";
import { showError } from "domain/actions/norr.action";
import { formationCategories, template } from "../../../../components/search/utils";

import styles from "../styles.module.css";
import SearchFilter from "pages/components/search";
import { Skeleton } from "primereact/skeleton";
import { getCityByCoord } from "domain/actions/city.action";
import { AwaitGeolocation } from "components/animation/geolocation";

export const Filter: React.FC = (props: any) => {
  const { 
    clearFilterCategories,
    setFilterCategories,
    setSorts, 
    categories, 
    showError,
    getCityByCoord ,
    loadingGeo,
  } = props;
  const [ data, setData ] = useState(template);
  const navigate = useNavigate();

  useEffect( () => { 
    setSorts('rating');
    setData([...formationCategories(categories), template[3]]) 
  }, [categories, setData, setSorts])

  const templeSkeleton = template.map( t => (
    <div className="p-d-flex p-ai-center p-flex-column p-jc-center p-col-3" key={t.id}>
      <Skeleton shape="circle" size="60px" className="p-p-2"></Skeleton>
      <Skeleton width="100%" className="p-mt-2"></Skeleton>
    </div>
  ))

  const handleFilter = (categoryId: string) => {
    if(categoryId.length === 1) return showError("Произошла ошибка! Возможно нестабильное интернет-соединение. Обновите страницу");

    clearFilterCategories();
    setSorts('geolocation', undefined);
    if(categoryId !== 'geo') setFilterCategories(categoryId)
    else if(categoryId === 'geo') {
      setSorts('geolocation', 'ASC')
      return getCityByCoord(() => {
        navigate(`/main/restaurants`)
      })
    }
    
    navigate(`/main/restaurants`)
  };

  const rerender = data.map( (item: any) => {
    return (
      <div key={item.id} style={{maxWidth: '134px'}} className="p-col-3 p-p-0 start-filter p-mt-2" onClick={()=>handleFilter(item.id)}>
        <div className="p-d-flex p-jc-center">
          <img src={item.image} alt={item.plural} className={styles['filter_icon']}/>
        </div>
        <h4 className={styles['filter_icon_name']}>{item.plural}</h4>
      </div>
    )
  } )

  return (
    <div className={styles['filter']}>
        <div className={styles["filter-description"]}>
          <h1 className="header-description-head ">
            Проверенные
            <br />
            заведения <strong>в одном месте</strong>
          </h1>

          <SearchFilter />
        </div>

        <div className="p-d-flex p-flex-wrap p-mt-2 p-mt-md-4 p-jc-around">
          {categories.length ? rerender : templeSkeleton}
        </div>

        <AwaitGeolocation loading={loadingGeo}/>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.categories,
  restaurants: state.restaurants.restaurants,
  loadRestaurants: state.restaurants.request.loading,
  loadingGeo: state.cities.geolocationLoad
})

export default connect(
  mapStateToProps, 
  { 
    clearFilterCategories,
    setFilterCategories,
    setSorts, 
    showError,
    getCityByCoord 
  } )(Filter);