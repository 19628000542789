import React from "react";

import styles from "../../styles.module.css";
import { useNavigate } from "react-router-dom";
import { isHTTPS } from "utils/regexps";

interface IProps {
  item: any
}

export const CardTask: React.FC<IProps> = (props: IProps) => {
  const { item } = props;
  const restaurant = item?.coupon?.restaurant ? item?.coupon?.restaurant : null;
  let history = useNavigate();

  const redirect = () => {
    if(item?.coupon?.restaurant) history(`/main/restaurant/${item.coupon.restaurant.id}`)
  };

  return (
    <div className="p-p-1 p-col-12">
      <div className={'block ' + styles['card']} style={{maxHeight: '114px', cursor: 'pointer'}} onClick={redirect} >
        <div className="p-col-12 p-p-0 p-d-flex">
          <img 
            src={`${isHTTPS(item?.coupon?.restaurant?.logo) ? item?.coupon?.restaurant?.logo : '/api/files/' + item?.coupon?.restaurant?.logo}`}
            onError={(e: any) => e.target.src='/api/admin/files/default'}
            alt={item?.coupon?.restaurant?.name}
            className={styles['card_image']}
            onClick={redirect}
          />

          <div className="p-ml-2 p-d-flex p-jc-between p-flex-column" style={{width: '100%'}}>
            <div>
              <h3 className={styles['card_header']}>
                {item?.coupon?.restaurant.name} 
                {restaurant && <span className={styles['card_header_city']}>({restaurant.city.name})</span>}
              </h3>
            
              {item?.raffles?.length === item?.counter ? <sup>(Разыгрывается)</sup> : <></>}  
            </div>

            <span className={styles['card_description']}>Тайных покупателей - {item?.raffles?.length} / {item?.counter}</span>
          </div>
        </div>

        
      </div>

      
    </div>
  )
}

export default CardTask