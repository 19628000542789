import React from "react";
import { PanelMenu } from 'primereact/panelmenu';
import { Sidebar } from "primereact/sidebar";
import { Menu } from 'primereact/menu';
import { Divider } from 'primereact/divider';
import { AuthComponent } from "pages/components";
import { useAuth } from "routes/config/ProvideAuth";
import { connect } from "react-redux";
import { logOut } from "domain/actions/auth.action";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.css";

const location = window.location;
const hostname = location.hostname;
const protocol = location.protocol;

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  position?: string;
  spyShow?: (value: boolean) => void;
}

export const MenuSidebar: React.FC<IProps> = (props: any) => {
  const auth = useAuth();
  const navigator = useNavigate();
  const { visible, setVisible, user, logOut, position="left", 
} = props;

  const host = hostname === 'localhost' ? 'localhost:3080' : (hostname === '3raza.com' || hostname === '3raza.ru') ? "owners.3raza.com" : "demo.owners.3raza.com";
  const url = protocol + "//" + host;

  const onNavigate = (url: string) => {
    navigator(url)
    setVisible(false)
  } 

  const ownerSecretShopper = [
    {
      label:'Купоны',
      icon:'pi pi-fw pi-ticket',
      command: () => onNavigate("/private/account/owner/secret_shopper"),
    }
  ];

  const clientSecretShopper = [
    {
      label:'Список заданий',
      icon:'pi pi-fw pi-calendar',
      command: () => onNavigate("/private/account/secret_shopper/shopper"),
    },
    {
      label:'Мои задания',
      icon:'pi pi-fw pi-user',
      command: () => onNavigate("/private/account/secret_shopper/tasks"),
    },
    {
      label:'Архив',
      icon:'pi pi-fw pi-briefcase',
      command: () => onNavigate("/private/account/secret_shopper/archive"),
    },
    {
      label:'Настройки',
      icon:'pi pi-fw pi-cog',
      command: () => onNavigate("/private/account/secret_shopper/setting"),
    }
  ];

  const simpleMenu = [
    {
      label:'На главную',
      icon:'pi pi-fw pi-home',
      command: () => onNavigate("/"),
      disabled: false
    },
    {
      label:'Список заведений',
      icon:'pi pi-fw pi-list',
      command: () => onNavigate("/main/restaurants"),
      disabled: false
    },
  ] as any[];

  const mixMenu = [
    {
      label:'Тайный покупатель',
      icon:'pi pi-fw pi-calendar',
      disabled: !auth.isAuthenticated,
      items: user?.role === 'owner' || user?.role === 'manager' ? ownerSecretShopper : clientSecretShopper
    }
  ]

  const account = {
    label:'Личный кабинет',
    icon:'pi pi-fw pi-home',
    disabled: !auth.isAuthenticated,
    items: [
      {
        label:'Мой профиль',
        icon:'pi pi-fw pi-user-edit',
        command: () => onNavigate("/private/account/personal"),
      },
      {
        label:'Мои акции',
        icon:'pi pi-fw pi-dollar',
        command: () => navigator("/private/account/stock")
      },
      {
        label:'Мои отзывы',
        icon:'pi pi-fw pi-envelope',
        command: () => navigator("/private/account/reviews")
      },
      {
        label:'Избранное',
        icon:'pi pi-fw pi-heart',
        command: () => onNavigate("/private/account/favorites"),
      }
    ]
  }

  const owners = [
    {
      label:'Панель администратора',
      icon:'pi pi-fw pi-user-edit',
      command: () => {
        window.open(`${url}`, '_blank');
      },
    }
  ];

  const logout = {
    label:'Выйти',
    icon:'pi pi-fw pi-power-off',
    command: () => logOut(),
  }

  const footerMenu = [
    logout
  ]

  if(auth.isAuthenticated && user?.role === 'client') simpleMenu.push(account);
  
  return (
    <Sidebar 
      visible={visible} 
      onHide={() => setVisible(false)} 
      className={styles['menu_sidebar'] + " menu-sidebar"}
      position={position}
    >
      <div className="menu-sidebar-header">
        <AuthComponent isMobile={true}/>
      </div>
      
      <PanelMenu model={simpleMenu} className="menu-sidebar-simple"/>
      
      {auth.isAuthenticated && (
        <>
          {(user?.role === 'owner' || user?.role === 'manager') && <PanelMenu model={owners} className="menu-sidebar-simple" />}
          {user?.role === 'client' && (
            <>
              <PanelMenu model={mixMenu} className="menu-sidebar-mix"/>
            </>
          )}
          
          <Divider />
          <Menu model={footerMenu} className="menu-sidebar-simple"/>
        </>
      )}
      
    </Sidebar>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.account.account,
});

export default connect(mapStateToProps, {logOut})(MenuSidebar);