import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";
import { FormLayout, ContentLayout } from "./layouts";

import { Dialog } from "primereact/dialog";

import styles from "../../style.module.css";

export const RestoreWindow: React.FC = (props: any) => {
  const { isShow, showWindow } = props;

  return (
    <Dialog
      className={styles.authWindow}
      header="Восстановление пароля"
      visible={isShow}
      onHide={() => showWindow('restore', false)}
      modal
      draggable={false}
    >
      <div className="p-col-12 p-p-0">
        <ContentLayout />
        <FormLayout />
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  isShow: state.auth.windows.restore
})

export default connect(mapStateToProps, {showWindow})(RestoreWindow);