import { combineReducers } from 'redux'
import * as constant from "domain/constants/hashtags.constant";
import * as types from "domain/types/hashtags.type";
import { IStatusResponse } from 'domain/types/status.type';

const initialState = {
  hashtags: [],
  current: null,
  request: {
    loading: false,
    error: null
  }
}


export function hashtags(state = initialState.hashtags, action: types.HashtagsReduceTypes): Array<types.IHashtag>{
  switch (action.type) {
    case constant.HASHTAGS_REDUCE_SET_ALL:
      return action.payload.data;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.HashtagsReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.HASHTAGS_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export default combineReducers({
  hashtags,
  request
})