export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const dataPickerSetting = {
  firstDayOfWeek: 1,
  dayNames: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ],
  dayNamesShort: ["Вс.", "Пн.", "Вт.", "Ср.", "Чт.", "Пт.", "Сб."],
  dayNamesMin: ["Вс.", "Пн.", "Вт.", "Ср.", "Чт.", "Пт.", "Сб."],
  monthNames: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  monthNamesShort: [
    "янв.",
    "фев.",
    "мар.",
    "апр.",
    "май.",
    "июн.",
    "июл.",
    "авг.",
    "сен.",
    "окт.",
    "ноя.",
    "дек.",
  ],
  today: "Сегодня",
  clear: "Очистить",
  dateFormat: "dd/mm/yy",
  weak: "Слабый",
  medium: "Средняя",
  strong: "Сильный",
  passwordPrompt: "Введите пароль",
  accept: "Да",
  reject: "Нет",
};
