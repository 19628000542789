import React from "react";

export const DevelopmentCard: React.FC = () => {
  return (
    <div
      className='p-col-12'
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/development.png"})`,
        width: '100%',
        height: 'inherit',
        backgroundPosition: 'center',
        minHeight: '240px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }}
    >
    </div>
  );
}

export default DevelopmentCard;