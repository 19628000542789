import { Component } from "react";

class ErrorBoundary extends Component {
  state = {
      error: null,
  };
  static getDerivedStateFromError(error: any) {
      return { error };
  }
  render() {
      const { error } = this.state as any;
      const bg = "/images/back_settings_page.png"
      
      if (error) {
          return (
            <div 
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}${bg})`,
                backgroundSize: 'contain',
                width: '100vw',
                height: '100vh',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            ></div>
          );
      }
      return this.props.children;
  }
}

export default ErrorBoundary;