import { ICategory } from "domain/types/restaurant.type";
import { orderBy } from "lodash";

export const formationCategories = (data: ICategory[]): any => {
  const _data = orderBy(data, ['createdAt'], ['desc'])
  return _data.map( (category: ICategory) => {
    switch (category.name) {

      case "Ресторан":
        return { ...category, type: 'category', plural: "Рестораны", image: process.env.PUBLIC_URL + "/images/restaurant_icon.png", color: "rgb(236 98 106)", class: 'color_pink' };
      case "Кофейня":
        return { ...category, type: 'category', plural: "Кофейни", image: process.env.PUBLIC_URL + "/images/coffee_icon.png", color: "rgb(190 104 50)", class: 'color_orange' };
      case "Кальянная":
        return { ...category, type: 'category', plural: "Центр паровых коктейлей", image: process.env.PUBLIC_URL + "/images/hookah_icon.png", color: "rgb(4 191 195)", class: 'color_blue' };
      
      default:
        return { ...category, type: 'category', plural: "", image: "" };;
    }
  })
}

export const template = [
  { id: '1', plural: "Рестораны", image: process.env.PUBLIC_URL + "/images/restaurant_icon.png", color: "rgb(236 98 106)", class: 'color_pink'},
  { id: '2', plural: "Кофейни", image: process.env.PUBLIC_URL + "/images/coffee_icon.png", color: "rgb(190 104 50)", class: 'color_orange' },
  { id: '3', plural: "Центр паровых коктейлей", image: process.env.PUBLIC_URL + "/images/hookah_icon.png", color: "rgb(4 191 195)", class: 'color_blue' },
  { id: 'geo', plural: "Рядом со мной", type: 'category', image: process.env.PUBLIC_URL + "/images/marker_icon.png", color: "rgb(4 191 195)", class: 'color_blue' }
]