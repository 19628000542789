import React from "react";
import { connect } from "react-redux";

import styles from "../../../style.module.css";
import { Card } from 'primereact/card';
import FooterLayout from "./footer.layout";

interface IProps {
  onChange: (role: string) => void;
  isPage?: boolean;
  showPage?: (name: string) => void;
}

const RoleLayout: React.FC<IProps> = (props: IProps) => {
  const {onChange, isPage = false, showPage = () => {}} = props;

  const roleTypes = [
    
    {
      label: 'Посетитель', 
      value: 'client', 
      description: <span> пользователь сайта, с возможностью участвовать в акциях <span style={{fontWeight: 600}}>"<u>Тайного&nbsp;покупателя</u>"</span>  </span> 
    },
    {
      label: 'Владелец', 
      value: 'owner', 
      description: <span> может добавлять на сайт свои заведения, а так-же пользоваться дополнительными услугами нашего сервиса. </span> 
    }
  ];

  return (
    <div className="p-col-12 p-p-1">
      <FooterLayout isPage={isPage} showPage={showPage}/>
      <br/>
      <h3 className="p-my-1">Выберите тип учетной записи</h3>
      {roleTypes.map((role: any) => {
        return (
          <Card title={role.label} subTitle={role.description} className="p-mt-2 p-shadow-3" key={role.label}>
            <button
              type="submit"
              className={styles.form_button}
              onClick={() => onChange(role.value) }
            >
              Выбрать
            </button>
          </Card >
        )
      })}
    </div>
  );
}

export default connect()(RoleLayout)
