import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';


export const CookiesDocsWin: React.FC = () => {
  const [ show, setShow ] = useState(false)
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if(!cookies.cookie_accept) setTimeout(() => setShow(true), 500) ;
  }, [cookies.cookie_accept]);

  const handleSetCookies = () => {
    setCookie("cookie_accept", true, {expires: new Date(new Date().getTime() + 86400000 * 365)})
    setShow(false)
  };

  return (
    <Dialog
      visible={show}
      position={"bottom"}
      modal={false}
      dismissableMask
      contentClassName="cookie-windows"
      showHeader={false}
      onHide={() => setShow(false)}
    >
      <div style={{ fontSize: '13px' }}>
        <div className="p-col-12 p-p-0 p-d-flex p-flex-between">
          <div className='p-col-11 p-p-1'>Уведомления о Cookies</div>
          <div className='p-col-1 p-p-0'> 
            <Button 
              icon="pi pi-times" 
              className="p-button-rounded p-button-outlined p-button-text" 
              style={{ color: 'white' }}
              onClick={() => setShow(false)}
            /> 
          </div>
        </div>
        <div className="p-col-12 p-pr-0 p-pl-0">
          <p>Наш сайт использует файлы cookie.
            <br/>
            Продолжая пользоваться сайтом, вы соглашаетесь на использование нами ваших файлов cookie <Link to="/main/docs/cookies" target="_blank" rel="noopener noreferrer"> <u style={{color: 'white'}}>Подробнее</u></Link> 
          </p>
          <Button 
            className="" 
            onClick={() => handleSetCookies()}
            label="Хорошо, спасибо"
          /> 
        </div>
      </div>
    </Dialog>
  );
}
      
export default CookiesDocsWin;