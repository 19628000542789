import React from "react";
import FormComponent from "./form.layout";

interface IProps {
  isPage?: boolean;
  showPage?: (name: string) => void;
}

export const Content: React.FC<IProps> = (props: IProps) => {
  const {isPage = false, showPage = () => {}} = props;

  return (
    <div className="p-col-12 p-p-0">
      <FormComponent isPage={isPage} showPage={showPage}/>
    </div>
  );
}

export default Content;