import { IQuestion, ITask } from 'domain/types/secretShopper.type';
import * as constant from "domain/constants/owner.constant";
import * as types from "domain/types/owner.type";
import { IGallery, IPostRestaurant, IRestaurant } from "domain/types/restaurant.type";

export function getRestaurantsByOwner(): types.OwnerSagaTypes{
  return {
    type: constant.OWNER_SAGA_GET_RESTAURANTS,
  }
}

export function getOrders(): types.OwnerSagaTypes{
  return {
    type: constant.OWNER_SAGA_GET_ORDERS
  }
}

export function getQuestionnaires() {
  return {
    type: constant.OWNER_SAGA_GET_QUESTIONNAIRES
  }
}

export function getQuestions() {
  return {
    type: constant.OWNER_SAGA_GET_QUESTIONS
  }
}

export function getQuestionnairesById(id: string) {
  return {
    type: constant.OWNER_SAGA_GET_QUESTIONNAIRE_BY_ID,
    payload: { questionnaireId: id }
  }
}

export function getQuestionnairesTemplateById(id: string, couponId: string) {
  return {
    type: constant.OWNER_SAGA_GET_QUESTIONNAIRE_TEMPLATE_BY_ID,
    payload: { questionnaireId: id, couponId: couponId }
  }
}

export function getBidsByOwner(){
  return {
    type: constant.OWNER_SAGA_GET_BIDS
  }
}

export function getCouponsByOwner(){
  return {
    type: constant.OWNER_SAGA_GET_COUPONS
  }
}

export function getStatusQr(qrId: string){
  return {
    type: constant.OWNER_SAGA_GET_STATUS_QR,
    payload: { qrId }
  }
}

export function getTaskById(taskId: string){
  return {
    type: constant.OWNER_SAGA_GET_TASK_BY_ID,
    payload: { taskId }
  }
}

export function getCouponById(couponId: string){
  return {
    type: constant.OWNER_SAGA_GET_COUPON_BY_ID,
    payload: { couponId }
  }
}

export function createRestaurant(restaurantData: IPostRestaurant): types.OwnerSagaTypes{
  return {
    type: constant.OWNER_SAGA_POST_RESTAURANT,
    payload: { data: restaurantData }
  }
}

export function updateRestaurant(restaurantData: any){
  return {
    type: constant.OWNER_SAGA_UPDATE_RESTAURANT,
    payload: { data: restaurantData }
  }
}

export function buyCoupon(restaurantId: string){
  return {
    type: constant.OWNER_SAGA_BUY_COUPON,
    payload: { restaurantId }
  }
}

export function createBid(data: any){
  return {
    type: constant.OWNER_SAGA_POST_BID,
    payload: { data }
  }
}

export function createBidQr(data: any){
  return {
    type: constant.OWNER_SAGA_POST_BID_QR,
    payload: { data }
  }
}

export function createQuestionnaire(data: types.ISchema){
  return {
    type: constant.OWNER_SAGA_POST_QUESTIONNAIRE,
    payload: { data }
  }
}

export function updateQuestionnaire(data: types.ISchema){
  return {
    type: constant.OWNER_SAGA_PUT_QUESTIONNAIRE,
    payload: { data }
  }
}

export function responseHelp(data: types.IResponseHelp ){
  return {
    type: constant.OWNER_SAGA_RESPONSE_HELP,
    payload: data
  }
}

export function clearQrData(){
  return { type: constant.OWNER_REDUCE_CLEAR_QR_DATA }
} 

export function getResultQrPayment(qrId: string){
  return { 
    type: constant.OWNER_SAGA_GET_RESULT_QR_DATA,
    payload: { qrId }
  }
}

export function setRestaurants(restaurants: Array<IRestaurant> ): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SET_RESTAURANTS,
    payload: { data: restaurants }
  }
}

export function setOrders(orders: types.IOrder[] ): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SET_ORDERS,
    payload: { data: orders }
  }
}

export function setQuestions(questions: IQuestion[] ): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SET_QUESTIONS,
    payload: { data: questions }
  }
}

export function setQuestionnaires(questionnaires: types.ISchema[] ): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SET_QUESTIONNAIRES,
    payload: { data: questionnaires }
  }
}

export function setQuestionnaire(questionnaire: types.ISchema ): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SET_CURRENT_QUESTIONNAIRE,
    payload: { questionnaire }
  }
}

export function selectedQuestionnaire(questionnaire: types.ISchema): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SELECTED_QUESTIONNAIRE,
    payload: { questionnaire }
  }
}

export function clearQuestionnaire(): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_CLEAR_QUESTIONNAIRE
  }
}

export function setBids(bids: Array<any>): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SET_BIDS,
    payload: { data: bids }
  }
}

export function setCoupons(coupons: Array<any>): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SET_COUPONS,
    payload: { data: coupons }
  }
}

export function setTask(task: ITask): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SELECT_TASK,
    payload: { data: task }
  }
}

export function setCoupon(coupon: types.ICoupon): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SELECT_COUPON,
    payload: { data: coupon }
  }
}

export function setQrData(data: any): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SET_QR_DATA_PAY,
    payload: { data }
  }
}

export function reqSuccess(success: boolean): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_CHECKOUT_REQUEST_SUCCESS,
    payload: { success }
  }
}

export function showWindow(window: string, isShow: boolean ): types.OwnerReduceTypes {
  return {
    type: constant.OWNER_REDUCE_IS_SHOW_WINDOW,
    payload: {
      window: window,
      isShow: isShow
    }
  }
}

export function deleteImageGallery(image: IGallery): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_DELETE_IMAGE_RESTAURANT,
    payload: {image}
  }
}

export function selectRestaurant(restaurant: IRestaurant): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_REDUCE_SELECT_RESTAURANT,
    payload: { restaurant }
  }
}

export function requireLoading(isLoad: boolean): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function requireLoadingQr(isLoad: boolean): types.OwnerReduceTypes{
  return {
    type: constant.OWNER_CHECKOUT_REQUEST_QR,
    payload: { loading: isLoad }
  }
}