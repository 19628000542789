import { combineReducers } from 'redux'
import * as constant from "domain/constants/secretShopper.constant";
import * as types from "domain/types/secretShopper.type";
import { IStatusResponse } from 'domain/types/status.type';

const initialState = {
  tasks: [],
  myTasks: [],
  archive: [],
  bankList: [],
  ranks: [],
  currentTask: null,
  raffle: [],
  upload: false,
  url: "",
  report: null,
  subscribe: null,
  currentQuestionnaire: null,
  windows: {
    report: false,
    questionnaire: false,
    card_number: false,
    receipt: false,
    subscribe: false,
    unsubscribe: false,
    telegram: false,
    winner: false,
  },
  filter: {
    cityId: null,
    districtId: null,
  },
  requestItem: {
    id: null,
    loading: false,
  },
  request: {
    loading: false,
    error: null
  }
}

export function bankList(state = initialState.bankList, action: types.SecretShopperReduceTypes): Array<types.IBankAlias> {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_BANK_LIST:
      return action.payload.banks;
    default:
      return state;
  }
}

export function ranks(state = initialState.ranks, action: types.SecretShopperReduceTypes): Array<types.IRank> {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_RANKS:
      return action.payload.ranks;
    default:
      return state;
  }
}

export function tasks(state = initialState.tasks, action: types.SecretShopperReduceTypes): Array<types.ITask> {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_TASKS:
      return action.payload.tasks;
    default:
      return state;
  }
}

export function myTasks(state = initialState.myTasks, action: types.SecretShopperReduceTypes): Array<types.ITask> {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_MY_TASKS:
      return action.payload.tasks;
    default:
      return state;
  }
}

export function archive(state = initialState.archive, action: types.SecretShopperReduceTypes): Array<types.ITask> {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_ARCHIVE_TASKS:
      return action.payload.tasks;
    default:
      return state;
  }
}

export function currentTask(state = initialState.currentTask, action: types.SecretShopperReduceTypes): types.ITask | null {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_CURRENT_TASK:
      return action.payload.task;
    default:
      return state;
  }
}

export function raffle(state = initialState.raffle, action: types.SecretShopperReduceTypes): Array<types.ITask> {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_RAFFLE_TASKS:
      return action.payload.tasks;
    default:
      return state;
  }
}

export function report(state = initialState.report, action: types.SecretShopperReduceTypes): any {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_REPORT_TASK:
      return action.payload.report;
    default:
      return state;
  }
}

export function subscribe(state = initialState.subscribe, action: types.SecretShopperReduceTypes): types.ISubscribe | null{
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_SUBSCRIBE:
      return action.payload.subscribe;
    default:
      return state;
  }
}

export function filter(state = initialState.filter, action: types.SecretShopperReduceTypes): types.IFilter {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_SET_CITY_FILTER:
      return {
        ...state,
        cityId: action.payload.cityId
      };
    case constant.SECRET_SHOPPER_REDUCE_SET_DISTRICT_FILTER:
      return {
        ...state,
        districtId: action.payload.districtId
      };
    default:
      return state;
  }
}

export function windows(state = initialState.windows, action: types.SecretShopperReduceTypes): types.ISecretShopperWindows {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REDUCE_IS_SHOW_WINDOW:
      const { window, isShow } = action.payload;
      return {
        ...state,
        [window]: isShow
      };
    default:
      return state;
  }
}

export function url(state = initialState.url, action: types.SecretShopperReduceTypes): string | null {
  switch (action.type) {
    case constant.SECRET_SHOPPER_SET_URL_RECEIPT:
      return action.payload.url;
    default:
      return state;
  }
}

export function upload(state = initialState.upload, action: types.SecretShopperReduceTypes): boolean {
  switch (action.type) {
    case constant.SECRET_SHOPPER_UPLOAD_RECEIPT:
      return action.payload.loading;
    default:
      return state;
  }
}

export function requestItem(state = initialState.requestItem, action: types.SecretShopperReduceTypes) {
  switch (action.type) {
    case constant.SECRET_SHOPPER_REQUEST_ITEM:
      return {
        id: action.payload.id,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.SecretShopperReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.SECRET_SHOPPER_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export function getCurrentTask(state: { secretShopper: {currentTask: types.ITask | null;} }) {
  return state.secretShopper.currentTask
}

export function getRaffle(state: { secretShopper: {raffle: any | null;} }){
  return state.secretShopper.raffle
}

export function getUrl(state: { secretShopper: {url: any | null;} }){
  return state.secretShopper.url
}

export function getParamsResponse(state: { secretShopper: { filters: types.IFilter }}) {
  const { filters } = state.secretShopper;
  let params = {} as any;

  params.cityId = filters.cityId;

  if(filters.districtId) params.districtId = filters.districtId

  return filters;
}

export function getSubscribe(state: { secretShopper: {subscribe: types.ISubscribe | null;} }) {
  return state.secretShopper.subscribe
}

export default combineReducers({
  bankList,
  tasks,
  myTasks,
  ranks,
  archive,
  currentTask,
  raffle,
  report,
  subscribe,
  filter,
  windows,
  upload,
  url,
  requestItem,
  request
})