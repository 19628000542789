import { combineReducers } from 'redux'
import * as constant from "domain/constants/news.constant";
import * as types from "domain/types/news.type";
import { IStatusResponse } from 'domain/types/status.type';

const initialState = {
  news: [],
  current: null,
  request: {
    loading: false,
    error: null
  }
}


export function news(state = initialState.news, action: types.NewsReduceTypes): Array<types.INews> {
  switch (action.type) {
    case constant.NEWS_REDUCE_SET_ALL:
      return action.payload.data;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.NewsReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.NEWS_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    case constant.NEWS_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export function getNews(state: { news: {news: Array<types.INews>}; }) {
  return state.news.news
}

export function getErrorNews(state: { news: {request: types.NewsReduceTypes} ; }){
  return state.news.request
}

export default combineReducers({
  news,
  request
})