import React, { useEffect } from "react";
import { connect } from "react-redux";

import styles from "../styles.module.css";
import CardRestaurant from "./cards/history.restaurant.card";
import CardNews from "./cards/history.news.card";
import { ScrollPanel } from 'primereact/scrollpanel';import { useCookies } from "react-cookie";
import { getRecentViews } from "domain/actions/main.action";

export const RecentViews: React.FC = (props: any) => {
  const {views, getRecentViews} = props;
  const [cookies] = useCookies();

  useEffect(() => {
    
    if(cookies.his_loc === undefined || cookies.his_loc.length === 0) return;
    getRecentViews(cookies.his_loc)
  }, [cookies.his_loc]) // eslint-disable-line react-hooks/exhaustive-deps

  const items = views.slice(0,4).map( (r: any, index: number) => {
    if(!r) return <></>;
    if(r.type === 'restaurant') return <CardRestaurant item={r.data} key={index}/>
    if(r.type === 'news') return <CardNews item={r.data} key={index}/>
    return <></>
  } )

  return (
    <div className={styles['recent_views'] + ' block'}>
      <h2 className={styles['title']}>Ваши последние просмотры</h2>

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">

        <ScrollPanel style={{ width: '100%', maxHeight: '345px' }} className="custombar">
          <div style={{ padding: '0 1em 1em 0'}} className="p-d-flex p-flex-wrap">
            {items.length ? items : 'У вас ещё не было активности на сайте'}
          </div>  
        </ScrollPanel>
        
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) =>({
  views: state.main.views,
});

export default connect(mapStateToProps, {getRecentViews} )(RecentViews);