import * as constant from "domain/constants/city.constant";
import * as types from "domain/types/city.type";

// Sagas
export function getCities(): types.CitiesSagaTypes{
  return {
    type: constant.CITIES_SAGA_GET_ALL,
  }
}

export function getCityById(cityId: string): types.CitiesSagaTypes{
  return {
    type: constant.CITIES_SAGA_GET_BY_ID,
    payload: { cityId }
  }
}

export function getDefaultCity(): types.CitiesSagaTypes{
  return {
    type: constant.CITIES_SAGA_GET_DEFAULT_CITY,
  }
}

export function getCityByCoord(cb = () => {}): types.CitiesSagaTypes{
  return {
    type: constant.CITIES_SAGA_GET_BY_COORD,
    payload: { cb }
  }
}

// Reducers
export function setCities(cities: Array<types.ICity> ): types.CitiesReduceTypes{
  return {
    type: constant.CITIES_REDUCE_SET_ALL,
    payload: { data: cities }
  }
}

export function setCitiesDefault(cities: Array<types.ICity> ): types.CitiesReduceTypes{
  return {
    type: constant.CITIES_REDUCE_SET_DEFAULT,
    payload: { data: cities.slice(0, 6), }
  }
}

export function selectCity(city: types.ICity): types.CitiesReduceTypes{
  return {
    type: constant.CITIES_REDUCE_SELECT,
    payload: { city }
  }
}

export function setErrorGeolocation(error: string): types.CitiesReduceTypes{
  return {
    type: constant.CITIES_REDUCE_SET_ERROR_GEOLOCATION,
    payload: { error }
  }
}

export function reqCity(isLoad: boolean): types.CitiesReduceTypes{
  return {
    type: constant.CITIES_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqErrorCity(error: any): types.CitiesReduceTypes{
  return {
    type: constant.CITIES_CHECKOUT_REQUEST_ERROR,
    payload: { error }
  }
}

export function reqGeolocation(isLoad: boolean): types.CitiesReduceTypes{
  return {
    type: constant.GEOLOCATION_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}