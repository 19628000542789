import { combineReducers } from 'redux'
import * as constant from "domain/constants/favorites.constant";
import * as types from "domain/types/favorites.type";
import { IRestaurant } from 'domain/types/restaurant.type';

const initialState = {
  restaurants: [],
  windows: {},
  request: {
    loading: false,
    error: null,
    success: null
  }
}


export function restaurants(state = initialState.restaurants, action: types.FavoritesReduceTypes): Array<IRestaurant>{
  switch (action.type) {
    case constant.FAVORITES_REDUCE_SET_RESTAURANTS:
      return action.payload.data;
    default:
      return state;
  }
}


export function request(state = initialState.request, action: types.FavoritesReduceTypes): any {
  switch (action.type) {
    case constant.FAVORITES_CHECKOUT_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case constant.FAVORITES_CHECKOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
      };
    case constant.FAVORITES_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        success: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export function getRestaurants(state: { favorites: {restaurants: Array<IRestaurant>}; }) {
  return state.favorites.restaurants
}

export default combineReducers({
  restaurants,
  request
})