import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { PasswordField, TextField } from "components/form/fields";
import { requiredValidator, composeValidators } from "components/form/validators";
import { restore } from 'domain/actions/auth.action';

import styles from "../../../style.module.css";
import { IRestoreForm } from "domain/types/auth.type";

const ContentLayout: React.FC = (props: any) => {
  const { restore, login } = props;
  const [ data ] = useState({ code: "", password: "", checkPassword: "", login: login })

  const onSubmit = (values: IRestoreForm) => restore(values);

  const confirmPass = (pass: string) => (value: any) =>
    pass === value ? undefined : `Пароли не совпадают`;

  return (
    <div className="p-col-12">
      <span
        className={
          styles.content_text +
          " p-col-12 p-p-0 p-mt-2 p-mb-4 p-d-block p-text-center"
        }
      >
        Мы отправили на указанный вами номер смс-сообщение с кодом подтверждения
        <br/>
        Введите код и придумайте новый пароль
      </span>

      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="p-mt-1 p-mb-3">
              <Field
                validate={composeValidators(requiredValidator)}
                name="code"
                label="Код подтверждения из смс-сообщения:"
                render={TextField}
                settings={{
                  placeholder: "Введите код подтверждения из смс-сообщения",
                }}
              />
            </div>

            <div className="p-mt-1 p-mb-3 p-d-none">
              <Field
                name="login"
                render={TextField}
              />
            </div>

            <div className="p-mt-1 p-mb-2">
              <Field
                validate={requiredValidator}
                name="password"
                label="Новый пароль:"
                render={PasswordField}
                settings={{
                  placeholder: "Придумайте новый пароль",
                  feedback: false,
                }}
                icon={["fas", "lock"]}
              />
            </div>

            <div className="p-mt-1 p-mb-2">
              <Field
                validate={composeValidators(
                  requiredValidator,
                  confirmPass(values.password)
                )}
                name="checkPassword"
                label="Повторить пароль:"
                render={PasswordField}
                settings={{
                  placeholder: "Введите пароль повторно",
                  feedback: false,
                }}
                icon={["fas", "check-circle"]}
              />
            </div>

            <div className="p-mt-1">
              <button
                type="submit"
                className={styles.form_button}
                disabled={submitting}
              >
                Подтвердить регистрацию
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  login: state.auth.restore
})

export default connect(mapStateToProps, {restore})(ContentLayout);