import React from "react";
import { connect } from "react-redux";
import { SignIn } from "./components";
import {
  UserMenuComponent
} from "./components";
import SignInDesktop from "./components/signInBtnDesktop";
import MobileMenuComponent from "./components/userMenu/mobile.menu";

interface IProps {
  isAuthenticated?: boolean;
  className?: string;
  isMobile?: boolean;
  onShowBurger?: () => void | undefined;
}

const AuthComponent: React.FC<IProps> = (props: IProps) => {
  let { isAuthenticated, isMobile, onShowBurger } = props;

  const mobile = (
    <div className={`p-col-12 p-d-flex p-flex-column p-ai-center`}>
      {isAuthenticated ? <MobileMenuComponent onShowBurger={onShowBurger}/> : <SignIn />}
    </div>
  )

  const desktop = (
    <div className={`p-col-12 p-d-flex p-ai-center p-jc-end`}>
      {isAuthenticated ? <UserMenuComponent /> : <SignInDesktop />}
    </div>
  )

  return (
    <React.Fragment>
      {isMobile ? mobile : desktop}
    </React.Fragment>
  );
}


const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, 
  // { setAuthInfo }
  )(AuthComponent);
