import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ConfirmPopup } from "primereact/confirmpopup";
import { Button } from "primereact/button";

import styles from "../style.module.css";
import { ICity } from "domain/types/city.type";

interface IComponentProps{
  city?: ICity | null;
  showFindCity: (isShow: boolean) => void;
}

const GeoPopup: React.FC<IComponentProps> = (props) => {
  const { city, showFindCity } = props;
  const [visible, setVisible] = useState(false);
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if(!cookies.s_city) setTimeout(() => setVisible(true), 500) ;
  }, [cookies.s_city]);
  
  const accept = () => {
    if(city) setCookie("s_city", city.id, {expires: new Date(new Date().getTime() + 86400000 * 365)});
    setVisible(false);
  };
  
  const reject = () => {
    setVisible(false);
    showFindCity(true);
  };
  
  const Content = () => (
    <div className={styles.popupContent}>
      <FontAwesomeIcon
        icon={["fas", "map-marker-alt"]}
        style={{ fontSize: "30px", marginRight: "10px" }}
      />
      <div>
        {`Ваш город ${ !!city ? city.name.toUpperCase() : "Не выбран"}?`}
      </div>
    </div>
  )

  const Footer = () => (
    <div className="p-confirm-popup-footer">
      <div className="p-text-left">
        <Button
          label="Да, верно"
          onClick={accept}
          className="c-bg-orange p-mb-sm-0"
        />
        <Button
          label="Нет, выбрать другой"
          onClick={reject}
          className="c-orange-outlined"
        />
      </div>
    </div>
  )

  return (
    <ConfirmPopup
      target={document.getElementById("currentCity") || undefined}
      visible={visible}
      dismissable={false}
      onHide={() => setVisible(false)}
      message={<Content />}
      className={styles.popup}
      footer={<Footer/>}
    />
  );
};

const mapStateToProps = (state: any) => ({
  city: state.cities.current,
});

export default connect(mapStateToProps)(GeoPopup);