import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import BillingController from '../controllers/Billing.controller';
import * as actions from "domain/actions/owner.action";
import * as constants from "domain/constants/owner.constant";
import { IPostRestaurant, IPutRestaurant, IRestaurant } from 'domain/types/restaurant.type';
import { Result } from 'domain/types/other.type';
import { getAccount } from 'domain/reducers/account.reduce';
import { NorrController } from 'domain/controllers/Response.controller';
import { getCurrentRestaurant } from 'domain/reducers/restaurant.reduce';
import { ICoupon, IOrder, IResponseHelp, ISchema } from 'domain/types/owner.type';
import ShopperController from 'domain/controllers/Shopper.controller';
import { IQuestion } from 'domain/types/secretShopper.type';
import { deleteCoupon } from '../actions/coupon.action';

const api = new ApiController();
const billing = new BillingController();
const norr = new NorrController();
const shopper = new ShopperController();

export async function getRestaurantsFetch(accountId: string): Promise<Result<Array<IRestaurant>>> {
  return await api.get(`/restaurants/account/${accountId}`)
}

export async function getOrdersFetch(): Promise<Result<IOrder[]>> {
  return await billing.get(`/orders`)
}

export async function getStatusQrFetch(qrId: string):Promise<Result<Array<any>>>{
  return await billing.get(`/orders/status/${qrId}`)
}

export async function getQuestionsFetch(): Promise<Result<IQuestion[]>>{
  return await api.get(`/shopper/questionnaires/questions`);
}

export async function getQuestionnairesFetch(): Promise<Result<ISchema[]>>{
  return await api.get(`/shopper/questionnaires`);
}

export async function getQuestionnaireByIdFetch(questionnaireId: string): Promise<Result<ISchema>>{
  return await api.get(`/shopper/questionnaires/${questionnaireId}`);
}

export async function getQuestionnaireTemplateByIdFetch(questionnaireId: string, couponId: string): Promise<Result<ISchema>>{
  return await api.get(`/shopper/questionnaires/template/${questionnaireId}`, { couponId: couponId });
}

export async function getCouponsFetch(): Promise<Result<Array<any>>>{
  return await shopper.get(`/coupons`)
}

export async function getRestaurantByIdFetch(restaurantId: string): Promise<Result<IRestaurant | undefined>> {
  return await api.get(`/restaurants/account/${restaurantId}`)
}

export async function getCouponByIdFetch(couponId: string): Promise<Result<ICoupon | undefined>> {
  return await shopper.get(`/coupons/${couponId}`)
}

export async function postRestaurantFetch(accountId: string, data: IPostRestaurant): Promise<Result<any>> {
  return await api.post(`/restaurants/account/${accountId}`, data)
}

export async function putRestaurantFetch(restaurantId: string, data: IPutRestaurant): Promise<Result<any>> {
  return await api.post(`/restaurants/${restaurantId}/bid_update`, data)
}

export async function sendResponseHelpFetch(data: IResponseHelp): Promise<Result<any>> {
  return await api.post(`/mail/help/restaurant`, data)
}

export async function postBidFetch(data: any): Promise<Result<any>> {
  return await billing.post(`/coupons/buy`, data)
}

export async function postBidQrFetch(data: any): Promise<Result<any>> {
  return await billing.post(`/orders/create`, data)
}

export async function postQuestionnaireFetch(data: ISchema): Promise<Result<any>>{
  return await api.post(`/shopper/questionnaires`, data)
}

export async function putQuestionnaireFetch(data: ISchema): Promise<Result<any>> {
  return await api.put(`/shopper/questionnaires/${data.id}`, data)
}

export async function postCouponFetch(data: any): Promise<Result<any>> {
  return await shopper.post(`/coupons/buy`, data)
}

export async function getResultQrPaymentFetch(qrId: string): Promise<Result<any>> {
  // return await billing.get(`/psb/sbp/qr/${qrId}`)
  return await billing.get(`/orders/status/qr/${qrId}`)
}

export async function uploadRestaurantFilesFetch(restaurantId: string, gallery: Array<File>): Promise<Result<any>>{
  const fd = new FormData();
  gallery.forEach( (image: any) => {
    fd.append('gallery', image, image.name)
  })
  return await api.post(`/restaurants/${restaurantId}/upload/gallery`, fd)
}

export async function uploadRestaurantUpdateFilesFetch(restaurantId: string, gallery: Array<File>): Promise<Result<any>> {
  const fd = new FormData();
  gallery.forEach( (image: any) => {
    fd.append('gallery', image, image.name)
  })
  return await api.post(`/restaurants/${restaurantId}/update/gallery`, fd)
}

export function* getRestaurants(): any{
  yield put(actions.requireLoading(true))

  const account = yield select(getAccount);

  const response = yield call(getRestaurantsFetch, account.accountId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setRestaurants(response.value))
  })

  yield put(actions.requireLoading(false))
}

export function* getOrders(): any{
  yield put(actions.requireLoading(true))

  const response = yield call(getOrdersFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setOrders(response.value))
  })

  yield put(actions.requireLoading(false))
}

export function* getQuestions(): any{
  yield put(actions.requireLoading(true))

  const response = yield call(getQuestionsFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setQuestions(response.value))
  })

  yield put(actions.requireLoading(false))
}

export function* getQuestionnaires(): any{
  yield put(actions.requireLoading(true))

  const response = yield call(getQuestionnairesFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setQuestionnaires(response.value))
  })

  yield put(actions.requireLoading(false))
}

export function* getQuestionnaireById(action: any): any{
  yield put(actions.requireLoading(true))

  const questionnaireId = action.payload.questionnaireId;

  const response = yield call(getQuestionnaireByIdFetch, questionnaireId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setQuestionnaire(response.value))
    yield put(actions.showWindow("viewQuestionnaire", true))
  })

  yield put(actions.requireLoading(false))
}

export function* getQuestionnaireTemplateById(action: any): any{
  yield put(actions.requireLoading(true))

  const questionnaireId = action.payload.questionnaireId;
  const couponId = action.payload.couponId;

  const response = yield call(getQuestionnaireTemplateByIdFetch, questionnaireId, couponId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setQuestionnaire(response.value))
    yield put(actions.showWindow("viewQuestionnaire", true))
  })

  yield put(actions.requireLoading(false))
}

// export function* getCoupons(): any{
//   yield put(actions.requireLoading(true))

//   const response = yield call(getCouponsFetch);
//   yield call(norr.processing, response, function *(){
//     yield put(actions.setBids(response.value))
//   })

//   yield put(actions.requireLoading(false))
// }

export function* getCoupons(): any{
  yield put(actions.requireLoading(true))

  const response = yield call(getCouponsFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCoupons(response.value))
  })

  yield put(actions.requireLoading(false))
}

export function* getStatusQr(action: any): any{
  const qrId = action.payload.qrId

  const response = yield call(getStatusQrFetch, qrId)
  yield call(norr.processing, response, function *(){
    yield put(actions.setQrData(response.value))
    yield put(actions.showWindow('qrStatus', true))
  })

}

export function* getRestaurantById(action: any): any{
  yield put(actions.requireLoading(true))

  const response = yield call(getRestaurantByIdFetch, action.payload.restaurantId)
  yield call(norr.processing, response, function *(){
    yield put(actions.selectRestaurant(response.value))
  })
  
  yield put(actions.requireLoading(false))
}

export function* postRestaurant(action: any): any{
  yield put(actions.requireLoading(true))

  const data = action.payload.data;

  data.metro = "";
  data.averageReceipt = "";

  const gallery = data.gallery;

  const account = yield select(getAccount);

  const response = yield call(postRestaurantFetch, account.accountId, data)
  const createRestaurant = yield call(norr.processing, response, function *(){})
  
  if(createRestaurant) {
    const resUpload = yield call(uploadRestaurantFilesFetch, response.value, gallery)

    yield call(norr.processing, resUpload, function *(){
      yield put(actions.reqSuccess(true))
    }, "Новое заведение создано")
  }
  
  yield put(actions.requireLoading(false))
}

export function* updateRestaurant(action: any): any {
  yield put(actions.requireLoading(true))
  const data = action.payload.data;
  const gallery = data.newGallery;
  const restaurant = yield select(getCurrentRestaurant)

  const response = yield call(putRestaurantFetch, restaurant.id, data)
  const updateRestaurant = yield call(norr.processing, response, function *(){})

  if(updateRestaurant) {
    const resUpload = yield call(uploadRestaurantUpdateFilesFetch, restaurant.id, gallery)

    yield call(norr.processing, resUpload, function *(){
      yield put(actions.reqSuccess(true))
    }, "Заявка на изменение данных создана")
  }
  
  yield put(actions.requireLoading(false))
}

// export function* postBid(action: any):any{
//   yield put(actions.requireLoading(true))

//   const data = action.payload.data;
//   data.type = 'secret_shopper';
//   data.regime = 'desktop';
//   data.returnUrl = document.location.href

//   const response = yield call(postBidFetch, data)

//   yield call(norr.processing, response, function *(){
//     yield put(actions.showWindow('createBid', false))
//     yield put(actions.clearQuestionnaire())
//     // window.location.replace(response.value);
//   }, "Заявка создана")
  
//   yield put(actions.requireLoading(false))
// }

export function* postBidQr(action: any):any{
  yield put(actions.requireLoadingQr(true))

  const data = action.payload.data;

  const temp = {
    typePayment: "qr",
    amount: data.amount
  }

  const response = yield call(postBidQrFetch, temp)

  yield call(norr.processing, response, function *(){
    yield put(actions.setQrData(response.value))
    data.paymentNumber = response.value.paymentNumber
  })

  if(response.type === "error") return;
  data.quantityShoppers = +data.quantityShoppers
  const resCoupon = yield call(postCouponFetch, data)

  yield call(norr.processing, resCoupon, function *(){
    yield put(actions.showWindow('qrPay', true))
    yield call(getCoupons)

    if(data.id) {
      yield call(deleteCoupon, data.id)
    }
  })
  
  yield put(actions.requireLoadingQr(false))
}

export function* postQuestionnaire(action: any): any{
  const schema = action.payload.data;

  const response = yield call(postQuestionnaireFetch, schema)

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('createQuestionnaire', false))
    yield call(getQuestionnaires)
  })
}

export function* putQuestionnaire(action: any): any{
  const schema = action.payload.data;

  const response = yield call(putQuestionnaireFetch, schema)

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('viewQuestionnaire', false))
    yield call(getQuestionnaires)
  })
}

export function* sendResponseHelp(action: any):any{

  const response = yield call(sendResponseHelpFetch, action.payload)
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('createRestaurantHelp', false))
    yield call(norr.success, "Заявка принята, в скором времени с Вами свяжется наш менеджер")
  })

}

export function* getResultQrPayment(action: any): any{
  const qrId = action.payload.qrId;
  const response = yield call(getResultQrPaymentFetch, qrId)

  yield call(norr.processing, response, function *(){
    if(response.value == null) return;
    if(response.value === "NEW" || response.value === "IN_PROGRESS") return;

    yield put(actions.showWindow('qrPay', false))
    yield put(actions.showWindow('createBid', false))
    yield put(actions.clearQuestionnaire())
    yield call(getCoupons)
  })
}

export function* getCouponById(action: any): any{
  yield put(actions.requireLoading(true))

  const couponId = action.payload.couponId;

  const response = yield call(getCouponByIdFetch, couponId)

  yield call(norr.processing, response, function *(){
    yield put(actions.setCoupon(response.value))
    yield put(actions.showWindow("coupon", true))
  })
  
  yield put(actions.requireLoading(false))
}

export function* buyCoupon({ type, payload }: {
  type: typeof constants.OWNER_SAGA_BUY_COUPON, 
  payload: any
}): any{
  yield put(actions.showWindow('createBid',true))
}

export function* watch() {
  yield takeEvery(constants.OWNER_SAGA_GET_RESTAURANTS, getRestaurants)
  yield takeEvery(constants.OWNER_SAGA_GET_ORDERS, getOrders)
  yield takeEvery(constants.OWNER_SAGA_GET_QUESTIONS, getQuestions)
  yield takeEvery(constants.OWNER_SAGA_GET_QUESTIONNAIRES, getQuestionnaires)
  yield takeEvery(constants.OWNER_SAGA_GET_QUESTIONNAIRE_BY_ID, getQuestionnaireById)
  yield takeEvery(constants.OWNER_SAGA_GET_QUESTIONNAIRE_TEMPLATE_BY_ID, getQuestionnaireTemplateById)
  yield takeEvery(constants.OWNER_SAGA_GET_BY_ID_RESTAURANT, getRestaurantById)
  yield takeEvery(constants.OWNER_SAGA_POST_RESTAURANT, postRestaurant)
  yield takeEvery(constants.OWNER_SAGA_GET_BIDS, getCoupons)
  // yield takeEvery(constants.OWNER_SAGA_POST_BID, postBid)
  yield takeEvery(constants.OWNER_SAGA_POST_BID_QR, postBidQr)
  yield takeEvery(constants.OWNER_SAGA_POST_QUESTIONNAIRE, postQuestionnaire)
  yield takeEvery(constants.OWNER_SAGA_PUT_QUESTIONNAIRE, putQuestionnaire)
  yield takeEvery(constants.OWNER_SAGA_RESPONSE_HELP, sendResponseHelp)
  yield takeEvery(constants.OWNER_SAGA_GET_RESULT_QR_DATA, getResultQrPayment)
  yield takeEvery(constants.OWNER_SAGA_UPDATE_RESTAURANT, updateRestaurant)
  yield takeEvery(constants.OWNER_SAGA_GET_COUPONS, getCoupons)
  yield takeEvery(constants.OWNER_SAGA_GET_COUPON_BY_ID, getCouponById)
  yield takeEvery(constants.OWNER_SAGA_BUY_COUPON, buyCoupon)
  yield takeEvery(constants.OWNER_SAGA_GET_STATUS_QR, getStatusQr)
}

export default function* Sagas() {
  yield fork(watch)
}