import { connect } from "react-redux";

export function DynamicRestaurantBreadcrumb(props: any) {
  const { current } = props;
  
  return  (
    <>
      <span>{current ? current.name : ' '}</span>
    </>
  );
}

const mapStateToProps = (state: any) =>({
  current: state.restaurants.current
});

export default connect(mapStateToProps)(DynamicRestaurantBreadcrumb);