import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/favorites.action";
import * as constants from "domain/constants/favorites.constant";
import { IRestaurant } from 'domain/types/restaurant.type';
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { getCurrentRestaurant } from 'domain/reducers/restaurant.reduce';
import { getAccount } from 'domain/reducers/account.reduce';
import { getCurrentMe } from './account.saga';
import { postToken } from './notifications.saga';

const api = new ApiController();
const norr = new NorrController();

export async function getRestaurantsFetch(): Promise<Result<Array<IRestaurant>>> {
  return await api.get(`/account/restaurant/favorites/account`)
}

export async function favoriteRestaurantFetch(restaurantId: string): Promise<Result<any>> {
  return await api.post(`/account/restaurant/favorites`, {restaurantId})
}

export async function unFavoriteRestaurantFetch(id: string): Promise<Result<any>> {
  return await api.delete(`/account/restaurant/favorites/${id}`)
}

export function* getRestaurants(): any{
  yield put(actions.requireLoading(true))

  const response = yield call(getRestaurantsFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setRestaurants(response.value))
  })

  yield put(actions.requireLoading(false))
}

export function* favoriteRestaurant(action: any): any{
  yield call(postToken)

  const response = yield call(favoriteRestaurantFetch, action.payload.restaurantId)
  yield call(norr.processing, response, function *(){
    yield call(getCurrentMe)
  })
}

export function* unFavoriteRestaurant(): any{
  const restaurant = yield select(getCurrentRestaurant)
  const account = yield select(getAccount);
  
  if(account && account?.favorites?.restaurants) {
    const favorites = account.favorites.restaurants;
    const index = favorites.map( (favorite:any) => favorite.restaurantId ).indexOf(restaurant.id);
    if(index < 0) return ;

    const response = yield call(unFavoriteRestaurantFetch, favorites[index].id)

    yield call(norr.processing, response, function *(){
      yield call(getCurrentMe)
    })
  }
}

export function* watch() {
  yield takeEvery(constants.FAVORITES_SAGA_GET_RESTAURANTS, getRestaurants)
  yield takeEvery(constants.FAVORITES_SAGA_FAVORITE_RESTAURANT, favoriteRestaurant)
  yield takeEvery(constants.FAVORITES_SAGA_UNFAVORITE_RESTAURANT, unFavoriteRestaurant)
}

export default function* Sagas() {
  yield fork(watch)
}