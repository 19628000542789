import React from "react";
import { connect } from "react-redux";
import { showWindow, registration } from 'domain/actions/auth.action';
import { Form, Field } from "react-final-form";
import {
  PasswordField,
  CheckboxField,
  KladrField,
  PhoneField,
} from "components/form/fields";
import {
  requiredValidator,
  composeValidators,
  phoneValidator,
  checkedValidator,
  minLengthValidator
} from "components/form/validators";

import styles from "../../../style.module.css";
import { IRegisterForm } from "domain/types/auth.type";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

interface IProps {
  role: string | null;
  setRole: (role: string | null) => void;
}

const FormLayout: React.FC<IProps> = (props: any) => {
  const {error, registration, role, setRole} = props;

  let formData = {
    phone: "",
    password: "",
    confirm: undefined,
    role: role,
    accept: true,
  };

  const confirmPass = (pass: string) => (value: any) =>
    pass === value ? undefined : `Пароли не совпадают`;

  const onSubmit = (values: IRegisterForm) => registration(values);

  return (
    <div className="p-col-12 p-pr-0 p-pl-0 p-pt-0">
      {error && (
        <span
          style={{ color: "red", fontSize: "14px" }}
          className="p-d-block p-p-0 p-col-12 p-text-center"
        >
          Пользователь с таким номером телефона уже зарегистрирован
        </span>
      )}
      
      <div className="p-d-flex p-ai-center p-col-12 cursor-pointer" onClick={() => setRole(null)}>
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          // onClick={() => setRole(null)}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />

        <div style={{width: '85%'}} className="p-text-center">
        {/* <p><strong>{role === 'client' ? 'Посетитель' : 'Владелец' } </strong> </p> */}
        Вернуться назад <strong>( {role === 'client' ? 'Посетитель' : 'Владелец' } )  </strong> 
        </div>
        {/* <strong>  Роль: {role === 'client' ? 'Посетитель' : 'Владелец' } </strong> */}
      </div>

      <Form
        onSubmit={onSubmit}
        initialValues={formData}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>

            <div className="p-mt-1 p-mb-2">
              <Field
                validate={composeValidators(requiredValidator, phoneValidator)}
                name="phone"
                label="Номер телефона"
                render={PhoneField}
                settings={{
                  placeholder: "Напишите свой номер телефона",
                  mask: "+00000000000",
                }}
                icon={["fas", "phone-alt"]}
              />
            </div>

            <div className="p-mt-1 p-mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="city"
                label="Город проживания"
                render={KladrField}
                settings={{
                  placeholder: "Введите свой город",
                  filterFromBound: "city",
                  filterToBound: "city",
                }}
              />
            </div>

            <div className="p-mt-1 p-mb-2">
            <span style={{fontSize: '10px'}}>Пароль должен содержать минимум 6 символов</span>
              <Field
                validate={composeValidators(
                  requiredValidator,
                  minLengthValidator(5)
                )}
                name="password"
                label="Пароль"
                render={PasswordField}
                settings={{
                  placeholder: "Введите пароль",
                  feedback: false,
                  toggleMask: true
                }}
                icon={["fas", "lock"]}
              />
              
            </div>

            <div className="p-mt-1 p-mb-2">
              <Field
                validate={composeValidators(
                  requiredValidator,
                  confirmPass(values.password)
                )}
                name="confirm"
                label="Повторить пароль"
                render={PasswordField}
                settings={{
                  placeholder: "Введите пароль повторно",
                  feedback: false,
                  toggleMask: true
                }}
                icon={["fas", "check-circle"]}
              />
            </div>

            <div className="p-mt-1 p-mb-2 p-pl-1">
              <Field
                name="confirmation"
                render={CheckboxField}
                validate={checkedValidator}
                inputId="confirmation"
              />
              <label className={styles.form_label} htmlFor="confirmation">
                Я ознакомился c <Link to="/main/docs/userAgreement" target="_blank" rel="noopener noreferrer">пользовательским соглашением</Link> и даю согласие на <Link to="/main/docs/dataProcessing" target="_blank" rel="noopener noreferrer"> обработку персональных данных</Link>
              </label>
            </div>

            <div className="p-mt-4">
              <button
                type="submit"
                className={styles.form_button}
                disabled={submitting}
              >
                Зарегистрироваться
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
}


const mapStateToProps = (state: any) => ({
  error: state.auth.request.error
})
export default connect(mapStateToProps, {showWindow, registration})(FormLayout)
