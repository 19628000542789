import React from "react";

import styles from "./style.module.css";

interface IProps {
  classGeolink: string;
  fullAddress: string;
  dataKey: string;
  description: string;
}

export const Geolink: React.FC<IProps> = (props: IProps) => {
  const { classGeolink, fullAddress, dataKey, description } = props;

  return (
    <React.Fragment>
      <span
        className={`ymaps-geolink geolink ${styles.ymaps} ${classGeolink} `}
        data-type="biz"
        data-description={description}
        data-key={dataKey}
        style={{
          letterSpacing: "-5px",
          width: "100%",
          position: "absolute",
          color: "#fff !important"
        }}
      >
        {fullAddress}
      </span>
    </React.Fragment>
  );
}

export default Geolink;
