import React from "react";

import styles from "../../styles.module.css";
import { toLengthString } from "utils/toLengthString";

interface IProps {
  item: any
}

export const CardNews: React.FC<IProps> = (props: IProps) => {
  const { item } = props;
  return (
    <div className="p-p-1 p-col-12 p-md-6 p-xl-3">
      <div className={'block ' + styles['card']}>
        <h4 className={styles['card_type']}>Статья</h4>
        <div className="p-col-12 p-p-0 p-d-flex">

          <div>
            <h3 className={styles['card_title']}>{item.name}</h3>
            <div>
              {item.image && (
                <img 
                  src={item.image}
                  alt={item.name}
                  className={styles['card_image']}
                  style={{float: 'left', marginRight: '6px', marginBottom: '4px'}}
                />
              )} 

              <span className={styles['card_description']}> {toLengthString(item.description, 92)} </span>
            </div>
            
          </div>
        </div>

        <div className={"p-col-12 p-p-0 p-text-right " + styles['card_next']}>Продолжить...</div>
      </div>
    </div>
  )
}

export default CardNews