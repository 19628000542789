export const RESTAURANTS_ACTION = "RESTAURANTS_ACTION";

export const RESTAURANTS_SAGA_GET_ALL_CITY = "RESTAURANTS_SAGA_GET_ALL_CITY";
export const RESTAURANTS_SAGA_GET_BY_ID = "RESTAURANTS_SAGA_GET_BY_ID";
export const RESTAURANTS_SAGA_GET_BY_FILTERS = "RESTAURANTS_SAGA_GET_BY_FILTERS";
export const RESTAURANTS_SAGA_POST = "RESTAURANTS_SAGA_POST";
export const RESTAURANTS_SAGA_PATCH = "RESTAURANTS_SAGA_PATCH";
export const RESTAURANTS_SAGA_DELETE = "RESTAURANTS_SAGA_DELETE";
export const RESTAURANTS_SAGA_GET_CATEGORIES = "RESTAURANTS_SAGA_GET_CATEGORIES";
export const RESTAURANTS_SAGA_GET_SIMILAR = "RESTAURANTS_SAGA_GET_SIMILAR";
export const RESTAURANTS_SAGA_GET_TASKS_CURRENT = "RESTAURANTS_SAGA_GET_TASKS_CURRENT";

export const RESTAURANTS_SAGA_GET_CATEGORIES_DISHES = "RESTAURANTS_SAGA_GET_CATEGORIES_DISHES";
export const RESTAURANTS_SAGA_GET_KITCHEN_DISHES = "RESTAURANTS_SAGA_GET_KITCHEN_DISHES";
export const RESTAURANTS_SAGA_GET_CATEGORIES_HOOKAH = "RESTAURANTS_SAGA_GET_CATEGORIES_HOOKAH";
export const RESTAURANTS_SAGA_GET_TAGS_DISHES = "RESTAURANTS_SAGA_GET_TAGS_DISHES";

export const RESTAURANTS_SAGA_POST_BID_MY = "RESTAURANTS_SAGA_POST_BID_MY";
export const RESTAURANTS_SAGA_GET_STOCKS = "RESTAURANTS_SAGA_GET_STOCKS";

export const RESTAURANTS_REDUCE_SELECT = "RESTAURANTS_REDUCE_SELECT";
export const RESTAURANTS_REDUCE_SET_ALL_CITY = "RESTAURANTS_REDUCE_SET_ALL_CITY";
export const RESTAURANTS_REDUCE_SET_VERIFIED = "RESTAURANTS_REDUCE_SET_VERIFIED";
export const RESTAURANTS_REDUCE_SET_TOP_CITY = "RESTAURANTS_REDUCE_SET_TOP_CITY";
export const RESTAURANTS_REDUCE_SET_BY_FILTERS = "RESTAURANTS_REDUCE_SET_BY_FILTERS";
export const RESTAURANTS_REDUCE_SET_CATEGORIES = "RESTAURANTS_REDUCE_SET_CATEGORIES";
export const RESTAURANTS_REDUCE_SET_CATEGORIES_HOOKAH = "RESTAURANTS_REDUCE_SET_CATEGORIES_HOOKAH";
export const RESTAURANTS_REDUCE_SET_SIMILAR = "RESTAURANTS_REDUCE_SET_SIMILAR";
export const RESTAURANTS_REDUCE_SET_TASKS_CURRENT = "RESTAURANTS_REDUCE_SET_TASKS_CURRENT";

export const RESTAURANTS_REDUCE_SET_KITCHENS_DISHES = "RESTAURANTS_REDUCE_SET_KITCHENS_DISHES";
export const RESTAURANTS_REDUCE_SET_CATEGORIES_DISHES = "RESTAURANTS_REDUCE_SET_CATEGORIES_DISHES";
export const RESTAURANTS_REDUCE_SET_TAGS_DISHES = "RESTAURANTS_REDUCE_SET_TAGS_DISHES";

export const RESTAURANTS_REDUCE_SET_FILTER_CATEGORIES = "RESTAURANTS_REDUCE_SET_FILTER_CATEGORIES";
export const RESTAURANTS_REDUCE_DELETE_FILTER_CATEGORIES = "RESTAURANTS_REDUCE_DELETE_FILTER_CATEGORIES";
export const RESTAURANTS_REDUCE_CLEAR_FILTER_CATEGORIES = "RESTAURANTS_REDUCE_CLEAR_FILTER_CATEGORIES";
export const RESTAURANTS_REDUCE_SET_FILTER_HASHTAGS = "RESTAURANTS_REDUCE_SET_FILTER_HASHTAGS";
export const RESTAURANTS_REDUCE_DELETE_FILTER_HASHTAGS = "RESTAURANTS_REDUCE_DELETE_FILTER_HASHTAGS";
export const RESTAURANTS_REDUCE_CLEAR_FILTER_HASHTAGS = "RESTAURANTS_REDUCE_CLEAR_FILTER_HASHTAGS";
export const RESTAURANTS_REDUCE_SET_FILTER_LIKE = "RESTAURANTS_REDUCE_SET_FILTER_LIKE";
export const RESTAURANTS_REDUCE_SET_FILTER_ADDING = "RESTAURANTS_REDUCE_SET_FILTER_ADDING";
export const RESTAURANTS_REDUCE_DELETE_FILTER_ADDING = "RESTAURANTS_REDUCE_DELETE_FILTER_ADDING";
export const RESTAURANTS_REDUCE_CLEAR_FILTER_ADDING = "RESTAURANTS_REDUCE_CLEAR_FILTER_ADDING";
export const RESTAURANTS_REDUCE_SET_FILTER_SPECIAL = "RESTAURANTS_REDUCE_SET_FILTER_SPECIAL";
export const RESTAURANTS_REDUCE_DELETE_FILTER_SPECIAL = "RESTAURANTS_REDUCE_DELETE_FILTER_SPECIAL";
export const RESTAURANTS_REDUCE_CLEAR_FILTER_SPECIAL = "RESTAURANTS_REDUCE_CLEAR_FILTER_SPECIAL";
export const RESTAURANTS_REDUCE_SET_FILTER_AVERAGE = "RESTAURANTS_REDUCE_SET_FILTER_AVERAGE";
export const RESTAURANTS_REDUCE_DELETE_FILTER_AVERAGE = "RESTAURANTS_REDUCE_DELETE_FILTER_AVERAGE";
export const RESTAURANTS_REDUCE_CLEAR_FILTER_AVERAGE = "RESTAURANTS_REDUCE_CLEAR_FILTER_AVERAGE";
export const RESTAURANTS_REDUCE_SET_PAGINATION_PAGE = "RESTAURANTS_REDUCE_SET_PAGINATION_PAGE";

export const RESTAURANTS_REDUCE_SET_SORTS = "RESTAURANTS_REDUCE_SET_SORTS";

export const RESTAURANTS_REDUCE_IS_SHOW_WINDOW = "RESTAURANTS_REDUCE_IS_SHOW_WINDOW";

export const RESTAURANTS_REDUCE_SET_ALL_COUNT = "RESTAURANTS_REDUCE_SET_ALL_COUNT";
export const RESTAURANTS_SAGA_SEARCH_BY_LIKE = "RESTAURANTS_SAGA_SEARCH_BY_LIKE";

export const RESTAURANTS_CHECKOUT_REQUEST = "RESTAURANTS_CHECKOUT_REQUEST";
export const RESTAURANTS_CHECKOUT_REQUEST_ERROR = "RESTAURANTS_CHECKOUT_REQUEST_ERROR";

export const RESTAURANTS_SAGA_GET_STOCKS_FILTER = "RESTAURANTS_SAGA_GET_STOCKS_FILTER";
export const RESTAURANT_REDUCE_SET__STOCKS_ALL_IN_MAIN = "RESTAURANT_REDUCE_SET__STOCKS_ALL_IN_MAIN";