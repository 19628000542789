import React from "react";
import { connect } from "react-redux";
import { updateAccountCity, showWindow } from "domain/actions/account.action";
import {showWindow as authActions} from 'domain/actions/auth.action';

import { Dialog } from "primereact/dialog";
import Search from "components/search";
import { getCityByCoord, getCityById } from "domain/actions/city.action";
import { ICity } from "domain/types/city.type";
import { Divider } from "primereact/divider";
import { AwaitGeolocation } from "components/animation/geolocation";

const FirstVisitWindow: React.FC = (props: any) => {
  const { cities, fasting, loading, isShow, showWindow, getCityById, getCityByCoord, updateAccountCity, authActions } = props;

  const handleSelect = (cityId: string) => {
    getCityById(cityId);
    updateAccountCity(cityId)
    handleClose()
  }

  const handleGeoBtnClick = () => {
    getCityByCoord(handleSelect);
  }

  const handleClose = () => {
    showWindow('first_visit', false);
    authActions('secretShopper', true);
  }

  let renderItem = fasting?.map((city: ICity) => {
    return (
      <span
        key={city.id}
        onClick={handleClose}
        style={{
          fontSize: '16px',
          fontWeight: 600,
          margin: '8px 0px',
          cursor: 'pointer'
        }}
      >
        {city.name}
      </span>
    );
  });

  return (
    <Dialog
      header={null}
      visible={isShow}
      onHide={() => showWindow('first_visit', false)}
      modal
      draggable={false}
      footer={<></>}
      style={{ minWidth: '350px' }}
      closeOnEscape={false}
    >
      <h3>Укажите город</h3>

      <div className="p-my-2"></div>

      <Search data={cities} onSelect={handleSelect} />

      <span 
        onClick={handleGeoBtnClick}
        style={{
          fontSize: '18px',
          color: '#8e3b40',
          fontWeight: 600,
          cursor: 'pointer'
        }}
      > Определить по геолокации </span>

      <Divider align="center" type="dashed"> <b>или</b> </Divider>

      <div className={`p-d-flex p-flex-wrap p-flex-column`}>
        {renderItem}
      </div>

      <AwaitGeolocation loading={loading}/>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.account.account,
  isShow: state.account.windows.first_visit,
  cities: state.cities.cities,
  fasting: state.cities.fasting,
  loading: state.cities.geolocationLoad
})

export default connect(
  mapStateToProps, 
  {
    showWindow, 
    getCityById, 
    getCityByCoord, 
    updateAccountCity,
    authActions
  }
)(FirstVisitWindow);
