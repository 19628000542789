import { put, call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from "domain/types/restaurant.type";
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { MAIN_SAGA_GET_HISTORY, setRecentViews } from 'domain/actions/main.action';

const api = new ApiController();
const norr = new NorrController();

export async function getHistoryFetch(history: { type: string, id: string }): Promise<Result<Array<types.IRestaurant>>> {
  return await api.get(`/main/recent`, { history })
}

export function* getHistory(action: any): any{
  const { history } = action.payload;

  const response = yield call(getHistoryFetch, history)
  yield call(norr.processing, response, function *(){
    yield put(setRecentViews(response.value))
  })

}

export function* watchMain() {
  yield takeEvery(MAIN_SAGA_GET_HISTORY, getHistory)
}

export default function* MainSagas() {
  yield fork(watchMain)
}