import { put, call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/email.action";
import * as constants from "domain/constants/email.constant";
import * as types from "domain/types/email.type";
import { NorrController } from 'domain/controllers/Response.controller';

const api = new ApiController();
const norr = new NorrController();

export async function postWriteToUSFetch(values: types.IWriteToUsForm): Promise<boolean | undefined> {
  return await api.post(`/mail/writetous`, values)
}

export function* sendWriteToUs(action: any): any{
  yield put(actions.reqEmail(true))

  const response = yield call(postWriteToUSFetch, action.payload.values)

  yield call(norr.processing, response, function* (){
    yield call(norr.success, 'Сообщение отправлено')
    action.payload.cb();
  }, "")

  yield put(actions.reqEmail(false))
}

export function* watchEmails() {
  yield takeEvery(constants.EMAIL_SAGA_SEND_WRITE_TO_US, sendWriteToUs)
}

export default function* EmailSagas() {
  yield fork(watchEmails)
}