import React from "react";

import styles from "../../../style.module.css";

interface IProps {
  onClick: () => void,
  second: number
}

const RepeatBtnLayout: React.FC<IProps> = (props: IProps) => {
  return (
    <div className="p-mt-1">
      
      {props.second <= 0 ? (
          <button className={styles.form_button} onClick={props.onClick}>
            Получить новый код
          </button>
        ) : (
          <div style={{fontSize: '12px', textAlign: 'center'}}>Повторно запросить код можно через {props.second}сек.</div>
        )
      }
      
    </div>
  );
}

export default RepeatBtnLayout;
