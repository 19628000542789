import React from "react";
import { Form, Field } from "react-final-form";
import { PhoneField } from "components/form/fields";
import {
  requiredValidator,
  composeValidators,
  phoneValidator,
} from "components/form/validators";

import styles from "../../../style.module.css";
import { connect } from "react-redux";
import { checkedRestore } from "domain/actions/auth.action";

export const FormLayout: React.FC = (props: any) => {
  const { checkedRestore } = props;
  let formData = {
    phone: "",
  };

  const onSubmit = async (values: any) => checkedRestore(values);

  return (
    <div className="p-col-12 p-pr-0 p-pl-0 p-pt-0">
      <Form
        onSubmit={onSubmit}
        initialValues={formData}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="p-mt-1 p-mb-2">
              <Field
                validate={composeValidators(requiredValidator, phoneValidator)}
                name="phone"
                label="Номер телефона:"
                render={PhoneField}
                settings={{
                  placeholder: "Введите свой номер телефона",
                  mask: "+00000000000",
                }}
              />
            </div>

            <div className="p-mt-4">
              <button
                type="submit"
                className={styles.form_button}
                disabled={submitting}
              >
                Восстановить
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default connect(null, { checkedRestore })(FormLayout);
