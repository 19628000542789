import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import Cookies from 'js-cookie';
import GeolocationController from 'domain/controllers/Geolocation.controller';
import { NorrController } from 'domain/controllers/Response.controller';
import * as actions from "domain/actions/city.action";
import * as constants from "domain/constants/city.constant";
import * as types from "domain/types/city.type";
import * as reducer from 'domain/reducers/city.reduce';
import { getRestaurants } from 'domain/actions/restaurant.action';
import { Result } from 'domain/types/other.type';
import { getAccount } from 'domain/reducers/account.reduce';
import { getPaginationRestaurants } from 'domain/reducers/restaurant.reduce';

const api = new ApiController();
const norr = new NorrController();

export function saveCookiesCity(cityId: string) {
  const isRemember = Cookies.get('remember_s_city');
  const days = isRemember === 'true' ? 365 : 0.5;
  Cookies.set('s_city', cityId, {expires: new Date(new Date().getTime() + 86400000 * days)})
}

export async function getCitiesFetch(): Promise<Result<Array<types.ICity>>> {
  return await api.get(`/cities`)
}

export async function getCityByIdFetch(id: string): Promise<Result<types.ICity>> {
  return await api.get(`/cities/${id}`)
}

export function* getCities(): any{
  yield put(actions.reqCity(true))

  const response = yield call(getCitiesFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setCities(response.value))
    yield put(actions.setCitiesDefault(response.value))
  })

  yield put(actions.reqCity(false))
}

export function* getCityById(action: any): any{
  const pagination = yield select(getPaginationRestaurants)
  yield put(actions.reqCity(true))
  const saveCity = Cookies.get('s_city');

  const response = yield call(getCityByIdFetch, action.payload.cityId)
  
  yield call(norr.processing, response, function *(){
    yield put(actions.selectCity(response.value))
    yield put(getRestaurants(response.value.id === saveCity ? pagination.page : 1))
    saveCookiesCity(response.value.id);
  })

  yield put(actions.reqCity(false))
}

export function* getCityByIdNotRefresh(action: any): any{
  console.log(action)
  const response = yield call(getCityByIdFetch, action.payload.cityId)
  
  yield call(norr.processing, response, function *(){
    yield put(actions.selectCity(response.value))
    saveCookiesCity(response.value.id);
  })
}

export function* getDefaultCity(): any{
  let defaultCityId = "2edae244-c184-42c8-9a75-6e0af612870f"; // Москва

  const account =  yield select(getAccount)
  if(account) defaultCityId = account.cityId
  
  const response = yield call(getCityByIdFetch, defaultCityId)

  yield call(norr.processing, response, function *(){
    yield put(actions.selectCity(response.value))
    yield put(getRestaurants(1))
  })

}

export function* getCityByCoord(action: any): any{
  yield put(actions.reqGeolocation(true))
  
  const { cb } = action.payload;
  const cities =  yield select(reducer.getCities)
  const geo = new GeolocationController(cities);
  
  const data = yield geo.getCity();

  if(data.city) {
    yield put(actions.selectCity(data.city))
    yield put(getRestaurants(1))

    saveCookiesCity(data.city.id);
    // Cookies.set('cityId', data.city.id, {expires: new Date(new Date().getTime() + 86400000 * 365)})

    cb(data.city.id);

    yield call(norr.success, `Определен город: ${data.city.name}`)
  }
  if(data.error) {
    yield call(norr.error, data.error)
    yield put(actions.setErrorGeolocation(data.error))
  }
  
  yield put(actions.reqGeolocation(false))
}

export function* watchCities() {
  yield takeEvery(constants.CITIES_SAGA_GET_ALL, getCities)
  yield takeEvery(constants.CITIES_SAGA_GET_BY_ID, getCityById)
  yield takeEvery(constants.CITIES_SAGA_GET_BY_COORD, getCityByCoord)
  yield takeEvery(constants.CITIES_SAGA_GET_DEFAULT_CITY, getDefaultCity)
}

export default function* CitySagas() {
  yield fork(watchCities)
}