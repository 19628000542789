import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const isWork = 'serviceWorker' in navigator;

const firebaseApp = isWork ? initializeApp(config) : null;
const messaging = isWork ? getMessaging(firebaseApp) : null;

export const fetchToken = async () => {

  if(!isWork) return false;

  return await getToken(
    messaging, 
    {vapidKey: process.env.REACT_APP_VAPID_KEY}
  ).then((currentToken) => {
    if (currentToken) {
      return currentToken;
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      return false;
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    // alert('При разрешении на получение уведомлений произошла ошибка.' + err  );
    console.log('An error occurred while retrieving token. ' + err );
    return false;
    // catch error while creating client token
  });
}

export const onMessageListener = () => {
  if(isWork) {
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log(payload)
        resolve(payload);
      });
    });; 
  }
}