import { combineReducers } from 'redux'
import * as constant from "domain/constants/account.constant";
import * as types from "domain/types/account.type";
import { IStatusResponse } from 'domain/types/status.type';
import { load } from 'redux-localstorage-simple';

const savedReduce: any = load();

const initialState = {
  isAuthenticated: false,
  account: (savedReduce && savedReduce.account) ? savedReduce.account.account : null,
  current: null,
  stocks: [],
  reviews: [],
  secretShopper: null,
  telegramToken: null,
  windows: {
    telegram: false,
    bind_phone: false,
    check_phone: false,
    first_visit: false,
  },
  request: {
    loading: false,
    error: null
  }
}

export function isAuthenticated(state = initialState.isAuthenticated, action: types.AccountReduceTypes): boolean {
  switch (action.type) {
    case constant.ACCOUNT_REDUCE_AUTHENTICATED:
      return action.payload.isAuthenticated;
    default:
      return state;
  }
}

export function account(state = initialState.account, action: types.AccountReduceTypes): types.IAccount | null {
  switch (action.type) {
    case constant.ACCOUNT_REDUCE_SET:
      return action.payload.account;
    case constant.ACCOUNT_REDUCE_CLEAR:
      return null;
    default:
      return state;
  }
}

export function telegramToken(state = initialState.telegramToken, action: types.AccountReduceTypes): any | null{
  switch (action.type) {
    case constant.ACCOUNT_REDUCE_SET_TELEGRAM_TOKEN:
      const { token } = action.payload
      return token;
    default:
      return state;
  }
}

export function secretShopper(state = initialState.secretShopper, action: types.AccountReduceTypes): any | null{
  switch (action.type) {
    case constant.ACCOUNT_REDUCE_SET_SECRET_SHOPPER:
      const { secretShopper } = action.payload
      return secretShopper;
    default:
      return state;
  }
}

export function stocks(state = initialState.stocks, action: types.AccountReduceTypes): any | null{
  switch (action.type) {
    case constant.ACCOUNT_REDUCE_SET_STOCKS:
      const { stocks } = action.payload
      return stocks;
    default:
      return state;
  }
}

export function reviews(state = initialState.reviews, action: types.AccountReduceTypes): any | null{
  switch (action.type) {
    case constant.ACCOUNT_REDUCE_SET_REVIEWS:
      const { reviews } = action.payload
      return reviews;
    default:
      return state;
  }
}

export function windows(state = initialState.windows, action: types.AccountReduceTypes): types.IAuthWindows {
  switch (action.type) {
    case constant.ACCOUNT_REDUCE_IS_SHOW_WINDOW:
      const { window, isShow } = action.payload;
      return {
        ...state,
        [window]: isShow
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.AccountReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.ACCOUNT_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    case constant.ACCOUNT_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export function getAccount(state: { account: {account: types.IAccount;} }) {
  return state.account.account
}

export function getSecretShopper(state: { account: {secretShopper: any} ; }){
  return state?.account.secretShopper
}

export function getErrorAccount(state: { stops: {request: types.AccountReduceTypes} ; }){
  return state.stops.request
}

export default combineReducers({
  isAuthenticated,
  account,
  stocks,
  reviews,
  secretShopper,
  telegramToken,
  windows,
  request
})