export const CITY_ACTION = "CITY_ACTION";

export const CITIES_SAGA_GET_ALL = "CITIES_SAGA_GET_ALL";
export const CITIES_SAGA_GET_BY_ID = "CITIES_SAGA_GET_BY_ID";
export const CITIES_SAGA_GET_BY_COORD = "CITIES_SAGA_GET_BY_COORD";
export const CITIES_SAGA_GET_DEFAULT_CITY = "CITIES_SAGA_GET_DEFAULT_CITY";
export const CITIES_SAGA_POST = "CITIES_SAGA_POST";

export const CITIES_REDUCE_SELECT = "CITIES_REDUCE_SELECT";
export const CITIES_REDUCE_SET_ALL = "CITIES_REDUCE_SET_ALL";
export const CITIES_REDUCE_SET_DEFAULT = "CITIES_REDUCE_SET_DEFAULT";
export const CITIES_REDUCE_SET_ERROR_GEOLOCATION = "CITIES_REDUCE_SET_ERROR_GEOLOCATION";

export const CITIES_CHECKOUT_REQUEST = "CITIES_CHECKOUT_REQUEST";
export const CITIES_CHECKOUT_REQUEST_ERROR = "CITIES_CHECKOUT_REQUEST_ERROR";

export const GEOLOCATION_CHECKOUT_REQUEST = "GEOLOCATION_CHECKOUT_REQUEST";