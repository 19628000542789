import { combineReducers } from 'redux'
import * as constant from "domain/constants/stock.constant";
import * as types from "domain/types/stock.type";
import { IStatusResponse } from 'domain/types/status.type';

const initialState = {
  stocks: [],
  current: null,
  currentStock: null, 
  windows: {
    phone: false,
    smsCode: false,
  },
  request: {
    loading: false,
    error: null
  }
}


export function stocks(state = initialState.stocks, action: types.StocksReduceTypes): Array<types.IStock>{
  switch (action.type) {
    case constant.STOCK_REDUCE_SET_ALL:
      return action.payload.data;
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.StocksReduceTypes): types.IStock | null {
  switch (action.type) {
    case constant.STOCK_REDUCE_SELECT:
      return action.payload.stock;
    case constant.STOCK_REDUCE_CLEAR:
      return null;
    default:
      return state; 
  }
}

export function currentStock(state = initialState.currentStock, action: types.StocksReduceTypes): types.IStock | null {
  switch (action.type) {
    case constant.STOCK_REDUCE_SELECT_RESTAURANT:
      return action.payload.stock;
    case constant.STOCK_REDUCE_CLEAR_RESTAURANT:
      return null;
    default:
      return state; 
  }
}

export function windows(state = initialState.windows, action: types.StocksReduceTypes): types.IStockWindows {
  switch (action.type) {
    case constant.STOCK_REDUCE_IS_SHOW_WINDOW:
      const { window, isShow } = action.payload;
      return {
        ...state,
        [window]: isShow
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.StocksReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.STOCK_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    case constant.STOCK_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export function getStocks(state: { stocks: {stocks: Array<types.IStock>}; }) {
  return state.stocks.stocks
}

export function getCurrentStock(state: { stocks: {current: types.IStock | null} ; }){
  return state?.stocks.current
}

export function getErrorStocks(state: { stocks: {request: types.StocksReduceTypes} ; }){
  return state.stocks.request
}

export default combineReducers({
  stocks,
  current,
  currentStock,
  windows,
  request
})