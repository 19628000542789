export const SECRET_SHOPPER_SAGA_GET_TASKS = "SECRET_SHOPPER_SAGA_GET_TASKS";
export const SECRET_SHOPPER_SAGA_GET_TASK_BY_ID = "SECRET_SHOPPER_SAGA_GET_TASK_BY_ID";
export const SECRET_SHOPPER_SAGA_GET_MY_TASKS = "SECRET_SHOPPER_SAGA_GET_MY_TASKS";
export const SECRET_SHOPPER_SAGA_GET_ARCHIVE_TASKS = "SECRET_SHOPPER_SAGA_GET_ARCHIVE_TASKS";
export const SECRET_SHOPPER_SAGA_GET_TASKS_FILTER = "SECRET_SHOPPER_SAGA_GET_TASKS_FILTER";
export const SECRET_SHOPPER_SAGA_GET_RAFFLE_TASKS = "SECRET_SHOPPER_SAGA_GET_RAFFLE_TASKS";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT = "SECRET_SHOPPER_REDUCE_SET_CURRENT";
export const SECRET_SHOPPER_REDUCE_SET_TASKS = "SECRET_SHOPPER_REDUCE_SET_TASKS";
export const SECRET_SHOPPER_REDUCE_SET_MY_TASKS = "SECRET_SHOPPER_REDUCE_SET_MY_TASKS";
export const SECRET_SHOPPER_REDUCE_SET_ARCHIVE_TASKS = "SECRET_SHOPPER_REDUCE_SET_ARCHIVE_TASKS";
export const SECRET_SHOPPER_REDUCE_SET_RAFFLE_TASKS = "SECRET_SHOPPER_REDUCE_SET_RAFFLE_TASKS";
export const SECRET_SHOPPER_REDUCE_SET_CURRENT_TASK = "SECRET_SHOPPER_REDUCE_SET_CURRENT_TASK";
export const SECRET_SHOPPER_REDUCE_SET_CITY_FILTER = "SECRET_SHOPPER_REDUCE_SET_CITY_FILTER";
export const SECRET_SHOPPER_REDUCE_SET_DISTRICT_FILTER = "SECRET_SHOPPER_REDUCE_SET_DISTRICT_FILTER";
export const SECRET_SHOPPER_SAGA_POST_CANCEL = "SECRET_SHOPPER_SAGA_POST_CANCEL";
export const SECRET_SHOPPER_SAGA_POST_RAFFLE = "SECRET_SHOPPER_SAGA_POST_RAFFLE";
export const SECRET_SHOPPER_SAGA_POST_MY_TASK = "SECRET_SHOPPER_SAGA_POST_MY_TASK";
export const SECRET_SHOPPER_SAGA_POST_REPORTER = "SECRET_SHOPPER_SAGA_POST_REPORTER";
export const SECRET_SHOPPER_SAGA_PUT_REPORTER = "SECRET_SHOPPER_SAGA_PUT_REPORTER";
export const SECRET_SHOPPER_SAGA_UPLOAD_RECEIPT = "SECRET_SHOPPER_SAGA_UPLOAD_RECEIPT";
export const SECRET_SHOPPER_SAGA_CHECK_SUBSCRIBE = "SECRET_SHOPPER_SAGA_CHECK_SUBSCRIBE";
export const SECRET_SHOPPER_SAGA_POST_SUBSCRIBE = "SECRET_SHOPPER_SAGA_POST_SUBSCRIBE";
export const SECRET_SHOPPER_SAGA_POST_UNSUBSCRIBE = "SECRET_SHOPPER_SAGA_POST_UNSUBSCRIBE";
export const SECRET_SHOPPER_SAGA_POST_SUBSCRIBE_FAVORITE = "SECRET_SHOPPER_SAGA_POST_SUBSCRIBE_FAVORITE";
export const SECRET_SHOPPER_SAGA_DELETE_TASK_BY_ID = "SECRET_SHOPPER_SAGA_DELETE_TASK_BY_ID";
export const SECRET_SHOPPER_SAGA_ARCHIVE_TASK_BY_ID = "SECRET_SHOPPER_SAGA_ARCHIVE_TASK_BY_ID";
export const SECRET_SHOPPER_SAGA_GET_DOWNLOAD_RECEIPT = "SECRET_SHOPPER_SAGA_GET_DOWNLOAD_RECEIPT";

export const SECRET_SHOPPER_REDUCE_IS_SHOW_WINDOW = "SECRET_SHOPPER_REDUCE_IS_SHOW_WINDOW";

export const SECRET_SHOPPER_SET_URL_RECEIPT = "SECRET_SHOPPER_SET_URL_RECEIPT";

export const SECRET_SHOPPER_CHECKOUT_REQUEST = "SECRET_SHOPPER_CHECKOUT_REQUEST";
export const SECRET_SHOPPER_UPLOAD_RECEIPT = "SECRET_SHOPPER_UPLOAD_RECEIPT";
export const SECRET_SHOPPER_REQUEST_ITEM = "SECRET_SHOPPER_REQUEST_ITEM";

export const SECRET_SHOPPER_SAGA_POST_CARD = "SECRET_SHOPPER_SAGA_POST_CARD";
export const SECRET_SHOPPER_SAGA_GET_REPORT_TASK = "SECRET_SHOPPER_SAGA_GET_REPORT_TASK";
export const SECRET_SHOPPER_REDUCE_SET_REPORT_TASK = "SECRET_SHOPPER_REDUCE_SET_REPORT_TASK";
export const SECRET_SHOPPER_REDUCE_SET_SUBSCRIBE = "SECRET_SHOPPER_REDUCE_SET_SUBSCRIBE";

export const SECRET_SHOPPER_SAGA_GET_BANKS_LIST = "SECRET_SHOPPER_SAGA_GET_BANKS_LIST";
export const SECRET_SHOPPER_REDUCE_SET_BANK_LIST = "SECRET_SHOPPER_REDUCE_SET_BANK_LIST";
export const SECRET_SHOPPER_SAGA_GET_RANKS = "SECRET_SHOPPER_SAGA_GET_RANKS";
export const SECRET_SHOPPER_REDUCE_SET_RANKS = "SECRET_SHOPPER_REDUCE_SET_RANKS";

export const SECRET_SHOPPER_REDUCE_SET__TASKS_ALL_IN_MAIN = "SECRET_SHOPPER_REDUCE_SET__TASKS_ALL_IN_MAIN";