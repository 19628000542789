import React from "react";

import styles from "../../../style.module.css";

export const BtnLayout: React.FC<{onClick: () => void}> = (props: {onClick: () => void}) => {
  return (
    <div className="p-mt-1">
      <button className={styles.form_button} onClick={props.onClick}>
        Авторизоваться
      </button>
    </div>
  );
}
