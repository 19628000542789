export const OWNER_SAGA_GET_RESTAURANTS = "OWNER_SAGA_GET_RESTAURANTS";
export const OWNER_SAGA_GET_BY_ID_RESTAURANT = "OWNER_SAGA_GET_BY_ID_RESTAURANT";
export const OWNER_SAGA_POST_RESTAURANT = "OWNER_SAGA_POST_RESTAURANT";
export const OWNER_SAGA_UPDATE_RESTAURANT = "OWNER_SAGA_UPDATE_RESTAURANT";
export const OWNER_SAGA_PUT_RESTAURANT = "OWNER_SAGA_PUT_RESTAURANT";
export const OWNER_SAGA_DELETE_RESTAURANT = "OWNER_SAGA_DELETE_RESTAURANT";

export const OWNER_SAGA_GET_ORDERS = "OWNER_SAGA_GET_ORDERS";
export const OWNER_SAGA_GET_QUESTIONNAIRES = "OWNER_SAGA_GET_QUESTIONNAIRES";
export const OWNER_SAGA_GET_QUESTIONNAIRE_BY_ID = "OWNER_SAGA_GET_QUESTIONNAIRE_BY_ID";
export const OWNER_SAGA_GET_QUESTIONNAIRE_TEMPLATE_BY_ID = "OWNER_SAGA_GET_QUESTIONNAIRE_TEMPLATE_BY_ID";
export const OWNER_SAGA_POST_QUESTIONNAIRE = "OWNER_SAGA_POST_QUESTIONNAIRE";
export const OWNER_SAGA_PUT_QUESTIONNAIRE = "OWNER_SAGA_PUT_QUESTIONNAIRE";

export const OWNER_SAGA_GET_QUESTIONS = "OWNER_SAGA_GET_QUESTIONS";

export const OWNER_SAGA_GET_BIDS = "OWNER_SAGA_GET_BIDS";
export const OWNER_SAGA_POST_BID = "OWNER_SAGA_POST_BID";
export const OWNER_SAGA_POST_BID_QR = "OWNER_SAGA_POST_BID_QR";
export const OWNER_SAGA_GET_COUPONS = "OWNER_SAGA_GET_COUPONS";

export const OWNER_SAGA_GET_STATUS_QR = "OWNER_SAGA_GET_STATUS_QR";

export const OWNER_SAGA_GET_TASK_BY_ID = "OWNER_SAGA_GET_TASK_BY_ID";
export const OWNER_SAGA_GET_COUPON_BY_ID = "OWNER_SAGA_GET_COUPON_BY_ID";
export const OWNER_SAGA_RESPONSE_HELP = "OWNER_SAGA_RESPONSE_HELP";

export const OWNER_REDUCE_SET_RESTAURANTS = "OWNER_REDUCE_SET_RESTAURANTS";
export const OWNER_REDUCE_CREATE_RESTAURANT = "OWNER_REDUCE_CREATE_RESTAURANT";
export const OWNER_REDUCE_SELECT_RESTAURANT = "OWNER_REDUCE_SELECT_RESTAURANT";
export const OWNER_REDUCE_SELECT_COUPON = "OWNER_REDUCE_SELECT_COUPON";

export const OWNER_REDUCE_SET_ORDERS = "OWNER_REDUCE_SET_ORDERS";
export const OWNER_REDUCE_SET_QUESTIONNAIRES = "OWNER_REDUCE_SET_QUESTIONNAIRES";
export const OWNER_REDUCE_SET_CURRENT_QUESTIONNAIRE = "OWNER_REDUCE_SET_CURRENT_QUESTIONNAIRE";
export const OWNER_REDUCE_SELECTED_QUESTIONNAIRE = "OWNER_REDUCE_SELECTED_QUESTIONNAIRE";
export const OWNER_REDUCE_CLEAR_QUESTIONNAIRE = "OWNER_REDUCE_CLEAR_QUESTIONNAIRE";

export const OWNER_REDUCE_SET_QUESTIONS = "OWNER_REDUCE_SET_QUESTIONS";

export const OWNER_REDUCE_SET_BIDS = "OWNER_REDUCE_SET_BIDS";
export const OWNER_REDUCE_SELECT_BID = "OWNER_REDUCE_SELECT_BID";
export const OWNER_REDUCE_SET_COUPONS = "OWNER_REDUCE_SET_COUPONS";

export const OWNER_REDUCE_SELECT_TASK = "OWNER_REDUCE_SELECT_TASK";

export const OWNER_REDUCE_SET_QR_DATA_PAY = "OWNER_REDUCE_SET_QR_DATA_PAY";
export const OWNER_REDUCE_CLEAR_QR_DATA = "OWNER_REDUCE_CLEAR_QR_DATA";
export const OWNER_SAGA_GET_RESULT_QR_DATA = "OWNER_SAGA_GET_RESULT_QR_DATA";

export const OWNER_REDUCE_IS_SHOW_WINDOW = "OWNER_REDUCE_IS_SHOW_WINDOW";

export const OWNER_REDUCE_DELETE_IMAGE_RESTAURANT = "OWNER_REDUCE_DELETE_IMAGE_RESTAURANT";

export const OWNER_SAGA_BUY_COUPON = "OWNER_SAGA_BUY_COUPON";

export const OWNER_CHECKOUT_REQUEST = "OWNER_CHECKOUT_REQUEST";
export const OWNER_CHECKOUT_REQUEST_QR = "OWNER_CHECKOUT_REQUEST_QR";
export const OWNER_CHECKOUT_REQUEST_ERROR = "OWNER_CHECKOUT_REQUEST_ERROR";
export const OWNER_RESTAURANT_SAGA_UPDATE_QUESTIONNAIRE_COMMENT = "OWNER_RESTAURANT_SAGA_UPDATE_QUESTIONNAIRE_COMMENT"
export const OWNER_CHECKOUT_REQUEST_SUCCESS = "OWNER_CHECKOUT_REQUEST_SUCCESS";

export const OWNER_RESTAURANT_SAGA_GET_QUESTIONNAIRES = "OWNER_RESTAURANT_SAGA_GET_QUESTIONNAIRES";
export const OWNER_RESTAURANT_SAGA_GET_QUESTIONNAIRE_BY_ID = "OWNER_RESTAURANT_SAGA_GET_QUESTIONNAIRE_BY_ID";
export const OWNER_RESTAURANT_REDUCE_SET_QUESTIONNAIRES = "OWNER_RESTAURANT_REDUCE_SET_QUESTIONNAIRES";
export const OWNER_RESTAURANT_REDUCE_SET_QUESTIONNAIRE_BY_ID = "OWNER_RESTAURANT_REDUCE_SET_QUESTIONNAIRE_BY_ID";