import { combineReducers } from 'redux'
import * as constant from "domain/constants/coupon.constant";
import * as types from "domain/types/coupon.type";
import { IStatusResponse } from 'domain/types/status.type';

const initialState = {
  coupon: null,
  request: {
    loading: false,
    error: null
  }
}

export function coupon(state = initialState.coupon, action: types.CouponReduceTypes): any | null {
  switch (action.type) {
    case constant.COUPON_REDUCE_SET:
      return action.payload.coupon;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.CouponReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.COUPON_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}

export default combineReducers({
  coupon,
  request,
})