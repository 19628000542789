import * as constants from "domain/constants/auth.constant";
import * as types from "domain/types/auth.type";

export function login(values: types.IAuthForm){
  return {
    type: constants.AUTH_ACTION_LOGIN,
    payload: values
  }
}

export function registration(values: types.IAuthForm){
  return {
    type: constants.AUTH_ACTION_REGISTRATION,
    payload: values
  }
}

export function restore(values: types.IRestoreForm){
  return {
    type: constants.AUTH_SAGA_RESTORE_PASSWORD,
    payload: values
  }
}

export function repeatCode(){
  return {
    type: constants.AUTH_SAGA_REPEAT_CODE
  }
}

export function checkedRestore(values: types.ICheckedRestoreForm){
  return {
    type: constants.AUTH_SAGA_RESTORE_PASSWORD_CHECKED,
    payload: values
  }
}

export function setDataRestore(phone: string): types.AuthReduceTypes{
  return {
    type: constants.AUTH_REDUCE_SET_DATA_RESTORE,
    payload: { restore: phone }
  }
}

export function logOut(){
  return {
    type: constants.AUTH_ACTION_LOGOUT,
  }
}

export function checkCode(values: types.IAuthForm) {
  return {
    type: constants.AUTH_ACTION_CHECK_CODE,
    payload: { values }
  }
}

export function showWindow(window: string, isShow: boolean ): types.AuthReduceTypes {
  return {
    type: constants.AUTH_REDUCE_IS_SHOW_WINDOW,
    payload: {
      window: window,
      isShow: isShow
    }
  }
}

export function setAuthenticated(isAuthenticated: boolean): types.AuthReduceTypes{
  return {
    type: constants.AUTH_REDUCE_SET_AUTH,
    payload: {isAuthenticated}
  }
}

export function reqAuth(isLoad: boolean): types.AuthReduceTypes{
  return {
    type: constants.AUTH_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqErrorAuth(error: any): types.AuthReduceTypes{
  return {
    type: constants.AUTH_CHECKOUT_REQUEST_ERROR,
    payload: { error }
  }
}

