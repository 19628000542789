import * as constant from "domain/constants/owner.constant";
import * as types from "domain/types/owner.type";

export function getQuestionnairesRestaurant(restaurantId: string): types.OwnerRestaurantSagaTypes{
  return {
    type: constant.OWNER_RESTAURANT_SAGA_GET_QUESTIONNAIRES,
    payload: {restaurantId: restaurantId}
  }
}

export function getQuestionnaireById(questionnaireId: string): types.OwnerRestaurantSagaTypes{
  return {
    type: constant.OWNER_RESTAURANT_SAGA_GET_QUESTIONNAIRE_BY_ID,
    payload: {questionnaireId: questionnaireId}
  }
}

export function setQuestionnairesRestaurant(questionnaires: types.ISchema[]): types.OwnerRestaurantReduceTypes{
  return {
    type: constant.OWNER_RESTAURANT_REDUCE_SET_QUESTIONNAIRES,
    payload: { data: questionnaires }
  }
}

export function setQuestionnaireById(questionnaire: types.ISchema): types.OwnerRestaurantReduceTypes{
  return {
    type: constant.OWNER_RESTAURANT_REDUCE_SET_QUESTIONNAIRE_BY_ID,
    payload: {data: questionnaire}
  }
}

export function updateComment(questionnaireId: string, comment: string): types.OwnerRestaurantSagaTypes{
  return {
    type: constant.OWNER_RESTAURANT_SAGA_UPDATE_QUESTIONNAIRE_COMMENT,
    payload: { questionnaireId, comment}
  }
}