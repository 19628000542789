export const STOCK_ACTION = "STOCK_ACTION";

export const STOCK_SAGA_GET_ALL = "STOCK_SAGA_GET_ALL";
export const STOCK_SAGA_GET_BY_ID = "STOCK_SAGA_GET_BY_ID";
export const STOCK_SAGA_POST_PHONE_CLIENT = "STOCK_SAGA_POST_PHONE_CLIENT";
export const STOCK_SAGA_SET_SMS_CODE = "STOCK_SAGA_SET_SMS_CODE";

export const STOCK_REDUCE_SELECT = "STOCK_REDUCE_SELECT";
export const STOCK_REDUCE_SET_ALL = "STOCK_REDUCE_SET_ALL";
export const STOCK_REDUCE_SET_PHONE_CLIENT = "STOCK_REDUCE_SET_PHONE_CLIENT";

export const STOCK_REDUCE_CLEAR = "STOCK_REDUCE_CLEAR";

export const STOCK_REDUCE_IS_SHOW_WINDOW = "STOCK_REDUCE_IS_SHOW_WINDOW";

export const STOCK_CHECKOUT_REQUEST = "STOCK_CHECKOUT_REQUEST";
export const STOCK_CHECKOUT_REQUEST_ERROR = "STOCK_CHECKOUT_REQUEST_ERROR";

export const STOCK_SAGA_ADD_STOCK_FOR_ACCOUNT = "STOCK_SAGA_ADD_STOCK_FOR_ACCOUNT";
export const STOCK_SAGA_GET_BY_ID_FOR_RESTAURANT = "STOCK_SAGA_GET_BY_ID_FOR_RESTAURANT";
export const STOCK_REDUCE_SELECT_RESTAURANT = "STOCK_REDUCE_SELECT_RESTAURANT";
export const STOCK_REDUCE_CLEAR_RESTAURANT = "STOCK_REDUCE_CLEAR_RESTAURANT";
export const STOCK_SAGA_COMPLETE = "STOCK_SAGA_COMPLETE";