import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { 
  clearFilterCategories, 
  setFilterCategories, 
  setLike, 
  getRestaurants, 
  setSorts,
  searchLikeRestaurants 
} from "domain/actions/restaurant.action";
import { showError } from "domain/actions/norr.action";
import { ListBox } from 'primereact/listbox';
import { Skeleton } from 'primereact/skeleton';

import { DebounceInput } from "react-debounce-input";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { formationCategories, template } from "./utils";

import styles from "./styles.module.css";
import { getCityByCoord } from "domain/actions/city.action";

export const SearchFilter: React.FC = (props: any) => {
  const { 
    clearFilterCategories, 
    setFilterCategories, 
    categories, 
    showError, 
    setLike,
    setSorts, 
    restaurants, 
    loadRestaurants,
    filterLike,
    getCityByCoord,
    searchLikeRestaurants
  } = props;
  const [ data, setData ] = useState(template);
  const [ filterData, setFilterData ] = useState(template);
  const [ valueSearch, setValueSearch ] = useState("Поиск по заведениям")
  const [width, setWidth] = useState('100px');
  const navigate = useNavigate();

  const op = useRef<any>(null);
  useEffect( () => { setData([...formationCategories(categories), template[3]]) }, [categories, setData])
  useEffect( () => { setFilterData([...formationCategories(categories), template[3]]) }, [categories])
  useEffect( () => { 
    const _restaurants = restaurants.slice(0,3) 
    if(_restaurants.length === 0) return setFilterData([...data]);
    
    setFilterData([...data.filter( r => r.plural.includes(filterLike)), ..._restaurants])
  }, [restaurants, filterLike] ) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilter = (categoryId: string) => {
    if(categoryId.length === 1) return showError("Произошла ошибка! Возможно нестабильное интернет-соединение. Обновите страницу");

    clearFilterCategories();
    setSorts('geolocation', undefined);
    if(categoryId !== 'geo') setFilterCategories(categoryId)
    else if(categoryId === 'geo') {
      setSorts('geolocation', 'ASC')
      return getCityByCoord(() => {
        navigate(`/main/restaurants`)
      })
    }
    
    navigate(`/main/restaurants`)
  };
  const handleRedirectRestaurant = (restaurantId: string) => {    
    navigate(`/main/restaurant/${restaurantId}`)
  };
  const handleSearch = (like: string) => {
    clearFilterCategories()
    setLike(like);
    searchLikeRestaurants();
  }

  const dataTemplate = (option: any) => {

    if(option?.type === 'category') return (
      <div 
        className="p-d-flex p-ai-center" 
        onMouseEnter={() => setValueSearch(option.plural)} 
        onMouseLeave={() => setValueSearch('Поиск по заведениям')}
      >
        <img 
          src={option.image} 
          alt={option.plural} 
          width={28} 
          className="p-mr-2" 
          onError={(e: any) => e.target.src = `${process.env.PUBLIC_URL}/images/Empty.png` }
        />
        <div>{option.plural}</div>
      </div>
    );

    return (
      <div 
        className="p-d-flex p-ai-center" 
        onMouseEnter={() => setValueSearch(option.name)} 
        onMouseLeave={() => setValueSearch('Поиск по заведениям')}
      >
        <img 
          src={
            option.logo ? `api/files/${option.logo}` : `${process.env.PUBLIC_URL}/images/Empty.png`
          } 
          alt={option.name} 
          style={{ borderRadius: '50%'}} 
          width={28} 
          height={28} 
          className="p-mr-2"
          onError={(e: any) => e.target.src = `${process.env.PUBLIC_URL}/images/Empty.png` } 
        />
        <div>{option.name}</div>
      </div>
    ) 
  }

  return (
    <div className="p-col-12 p-pb-0">
      <div className={styles['input_group']}>
              
        <span className={styles['float_label'] + " p-input-icon-left"}>
          <i className={styles['float_icon'] + " pi pi-search"} />
          <DebounceInput
            minLength={0}
            id="debounce-input-search"
            debounceTimeout={300}
            // autocomplete="off"
            placeholder={valueSearch}
            className={styles['search_input'] + " p-component"}
            onChange={event => handleSearch(event.target.value)} 
            onFocus={(e: any) => {
              setWidth(e.target.clientWidth + "px");
              op?.current?.show(e)
            } }
            onBlur={() => {
              setTimeout(() => op?.current?.hide(), 300)
            }}
          />
        </span>
        <Button 
          className={styles['search_btn']} 
          onClick={()=> {
            const _input = document.getElementById('debounce-input-search')
            if(_input) _input.focus();
          }}
          style={{boxShadow: "none"}}
          label="НАЙТИ"
        />

        <OverlayPanel 
          ref={op} 
          id="overlay_panel" 
          style={{width: width}} 
          className="overlay-panel--search" 
          dismissable={false}
        >
          {loadRestaurants ? 
            <div className="p-p-2">
              <div className="p-d-flex p-ai-center p-mb-2">
                <Skeleton shape="circle" size="40px" className="p-mr-2"></Skeleton>
                <div style={{ flex: '1' }}>
                  <Skeleton width="100%" className="p-mb-2"></Skeleton>
                </div>
              </div>
              <div className="p-d-flex p-ai-center">
                <Skeleton shape="circle" size="40px" className="p-mr-2"></Skeleton>
                  <div style={{ flex: '1' }}>
                    <Skeleton width="100%" className="p-mb-2"></Skeleton>
                  </div>
              </div>
            </div> 
            : <ListBox value={null} options={filterData} onChange={(e) => {
              if(e.value?.type === 'category') return handleFilter(e.value.id)

              handleRedirectRestaurant(e.value.id)
            } } itemTemplate={dataTemplate} />}
                
        </OverlayPanel>
      </div>

    </div>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.restaurants.categories,
  restaurants: state.restaurants.restaurants,
  loadRestaurants: state.restaurants.request.loading,
  filterLike: state.restaurants.filters.like
})

export default connect(
  mapStateToProps, 
  { 
    clearFilterCategories, 
    setFilterCategories, 
    showError, 
    setLike, 
    getRestaurants, 
    setSorts,
    getCityByCoord,
    searchLikeRestaurants 
  } )(SearchFilter);