import { combineReducers } from 'redux'
import * as constant from "domain/constants/filter.constant";
import * as types from "domain/types/filter.type";
import { IStatusResponse } from 'domain/types/status.type';

const initialState = {
  filters: [],
  selects: [],
  request: {
    loading: false,
    error: null
  }
}


export function filters(state = initialState.filters, action: types.FilterReduceTypes): Array<types.IFilter> {
  switch (action.type) {
    case constant.FILTER_REDUCE_SET_ALL:
      return action.payload.data;
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.FilterReduceTypes): IStatusResponse {
  switch (action.type) {
    case constant.FILTER_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    case constant.FILTER_CHECKOUT_REQUEST_ERROR:
      return {
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export function getFilters(state: { filters: {filters: Array<types.IFilter>}; }) {
  return state.filters.filters
}

export function getErrorFilter(state: { filters: {request: types.FilterReduceTypes} ; }){
  return state.filters.request
}

export default combineReducers({
  filters,
  request
})