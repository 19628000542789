import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/restaurant.action";
import * as constants from "domain/constants/restaurant.constant";
import * as types from "domain/types/restaurant.type";
import { ICity } from 'domain/types/city.type';
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { getCurrentRestaurant, getFilterRestaurants, getSortsRestaurants } from 'domain/reducers/restaurant.reduce';
import { getCities, getCurrentCity } from 'domain/reducers/city.reduce';
import GeolocationController from 'domain/controllers/Geolocation.controller';
import Cookies from 'js-cookie';
import { setStocksMain } from 'domain/actions/main.action';
import { getCityByIdNotRefresh, saveCookiesCity } from './city.saga';
import { selectCity } from 'domain/actions/city.action';

const api = new ApiController();
const norr = new NorrController();

interface IFilters {
  categories: Array<string>;
  hashtags: Array<string>;
  cityId: string;
};

interface ISorts {
  geolocation: string | undefined;
  rating: string | undefined;
  averageReceipt: string | undefined;
};

export interface IPagination {
  page: number;
  pageSize: number;
}

export async function getRestaurantsFetch(city: ICity, filters: IFilters): Promise<Result<Array<types.IRestaurant>>> {
  return await api.get(`/restaurants/${city.id}/city`, { filters })
}

export async function getRestaurantsFilterFetch(filters: IFilters, pagination: IPagination, sorts: ISorts): Promise<Result<Array<types.IRestaurant>>> {
  return await api.get(`/restaurants/filter`, { filters, pagination, sorts })
}

export async function getRestaurantsCoordinatesFetch(filters: IFilters, pagination: IPagination, coords: any): Promise<Result<Array<types.IRestaurant>>> {
  return await api.get(`/restaurants/filter`, { filters, pagination, undefined, coords })
}

export async function getTopRestaurantsFetch(city: ICity, filters: IFilters): Promise<Result<Array<types.IRestaurant>>> {
  return await api.get(`/restaurants/${city.id}/top`, { filters })
}

export async function getRestaurantByIdFetch(restaurantId: string): Promise<Result<types.IRestaurant | undefined>> {
  return await api.get(`/restaurants/${restaurantId}`)
}

export async function postBidMyFetch(restaurantId: string, values: types.IRestaurantBidMy): Promise<Result<any>>{
  return await api.post(`/restaurants/${restaurantId}/bid_my`, values)
}

export async function getCategoriesFetch(): Promise<Result<any>>{
  return await api.get(`/restaurants/categories`)
}

export async function getCategoriesDishesFetch(): Promise<Result<any>>{
  return await api.get(`/dishes/category`)
}

export async function getKitchenDishesFetch(): Promise<Result<any>>{
  return await api.get(`/dishes/national`)
}

export async function getTagsDishesFetch(): Promise<Result<any>>{
  return await api.get(`/dishes/tags`)
}

export async function getCategoriesHookahFetch(): Promise<Result<any>>{
  return await api.get(`/hookah/category`)
}

export async function getStocksFilterFetch(filters: { cities: string[] }, pagination: IPagination): Promise<Result<any>> {
  return await api.get(`/main/restaurants/coupons`, { filters, pagination });
}

export async function getSimilarFetch(restaurantId: string): Promise<Result<any>> {
  return await api.get(`/restaurants/similar`, { filters: { restaurantId } });
}

export async function getTasksRestaurantFetch(restaurantId: string): Promise<Result<any>> {
  return await api.get(`/restaurants/tasks`, { restaurantId });
}

function* getRestaurantsByGeolocation(params: any): any{
  const {pagination, filters } = params;

  const cities =  yield select(getCities)
  const geo = new GeolocationController(cities);

  const data = yield geo.getCity();
  if(data.city) {
    yield put(selectCity(data.city))
    saveCookiesCity(data.city.id);
    filters.cities = [data.city.id];
  }

  const cookie = Cookies.get('sv.c.crd') // save current coordinate
  const coordinates = cookie ? cookie : yield geo.getCoordinates(); 

  if(coordinates?.coords === undefined) {
    yield call(norr.error, coordinates?.message)
    yield put(actions.setSorts('geolocation', undefined) )
    yield call(getRestaurantsMain, params)
    return;
  }

  const coords = {
    latitude: coordinates.coords.latitude,
    longitude: coordinates.coords.longitude
  }
  const response = yield call(getRestaurantsCoordinatesFetch, filters, pagination, coords)
    yield call(norr.processing, response, function *(){
      yield put(actions.setAllCountRestaurants(response.value.count))

      const restaurants = response.value.rows;
      yield put(actions.setRestaurants(restaurants))
    })
}

function* getRestaurantsMain(params: any): any{
  const {pagination, filters, sorts } = params;

  const response = yield call(getRestaurantsFilterFetch, filters, pagination, sorts)
    yield call(norr.processing, response, function *(){
      yield put(actions.setAllCountRestaurants(response.value.count))

      const restaurants = response.value.rows;
      yield put(actions.setRestaurants(restaurants))
    })
}

export function* getSimilar(action: any): any{
  const {restaurantId} = action.payload; 

  const response = yield call(getSimilarFetch, restaurantId)

  yield call(norr.processing, response, function *(){
    yield put(actions.setSimilar(response.value))
  })
}

export function* getTasksRestaurant(action: any): any{
  const {restaurantId} = action.payload; 

  const response = yield call(getTasksRestaurantFetch, restaurantId)

  yield call(norr.processing, response, function *(){
    yield put(actions.setTasks(response.value))
  })
}

export function* getRestaurants(action: any): any{
  const {page, pageSize = 10, isLoading} = action.payload;
  const pagination = {page: page, pageSize: pageSize}
  const filters = yield select(getFilterRestaurants);
  const sorts = yield select(getSortsRestaurants);
  const city = yield select(getCurrentCity);

  filters.cities = [city.id];

  yield put(actions.setPaginationPage(page));

  if(isLoading) yield put(actions.reqRestaurants(true))

  // if(page === 1) yield put(actions.setAllCountRestaurants(0))
  if(sorts.geolocation) {
    yield call(getRestaurantsByGeolocation, { pagination, filters, sorts })
  }else {
    yield call(getRestaurantsMain, { pagination, filters, sorts, })
  }

  yield put(actions.reqRestaurants(false))
}

export function* searchLikeRestaurants(action: any): any{
  const {isLoading} = action.payload;
  const pagination = {page: 1, pageSize: 5}
  const filters = yield select(getFilterRestaurants);
  const sorts = yield select(getSortsRestaurants);

  filters.cities = [];

  if(isLoading) yield put(actions.reqRestaurants(true))

  yield call(getRestaurantsMain, { pagination, filters, sorts, })

  yield put(actions.reqRestaurants(false))
}

export function* getRestaurantById(action: any): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(getRestaurantByIdFetch, action.payload.restaurantId)

  yield call(norr.processing, response, function *(){
    yield put(actions.selectRestaurant(response.value))
    yield call(getCityByIdNotRefresh, { payload: {cityId: response.value.cityId} })
  })
  
  yield put(actions.reqRestaurants(false))
}

export function* postBidMy(action: any): any{
  yield put(actions.reqRestaurants(true))

  const restaurant = yield select(getCurrentRestaurant)
  const values = {
    phone: action.payload.values.phone
  }
  
  const response = yield call( postBidMyFetch, restaurant.id, values)

  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow("establishment", false))
  })
  
  yield put(actions.reqRestaurants(false))
}

export function* getCategories(): any{
  const response = yield call( getCategoriesFetch )

  yield call(norr.processing, response, function *(){
    yield put(actions.setCategories(response.value))
  })
}

export function* getCategoriesDishes(): any{
  const response = yield call( getCategoriesDishesFetch )

  yield call(norr.processing, response, function *(){
    yield put(actions.setCategoriesDish(response.value))
  })
}

export function* getKitchenDishes(): any{
  const response = yield call( getKitchenDishesFetch )

  yield call(norr.processing, response, function *(){
    yield put(actions.setKitchens(response.value))
  })
}

export function* getTagsDishes(): any{
  const response = yield call( getTagsDishesFetch )

  yield call(norr.processing, response, function *(){
    yield put(actions.setTagsDishes(response.value))
  })
}

export function* getCategoriesHookah(): any{
  const response = yield call( getCategoriesHookahFetch )

  yield call(norr.processing, response, function *(){
    yield put(actions.setCategoriesHookah(response.value))
  })
}

export function* getStocksByFilter(action: any):any{
  const {page, pageSize = 25, isLoading} = action.payload;
  const pagination = {page: page, pageSize: pageSize}
  const city = yield select(getCurrentCity);
  const filters = {
    cities: city ? [city.id] : [],
  }
  
  if( isLoading ) yield put(actions.reqRestaurants(true))

  const response = yield call(getStocksFilterFetch, filters, pagination)
  
  if(yield call(norr.processing, response, function *(){})) {
    yield put(setStocksMain(response.value))
  }  

  yield put(actions.reqRestaurants(false))
}

export function* watchRestaurants() {
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_ALL_CITY, getRestaurants)
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_BY_ID, getRestaurantById)
  yield takeEvery(constants.RESTAURANTS_SAGA_POST_BID_MY, postBidMy)
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_CATEGORIES, getCategories)
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_CATEGORIES_DISHES, getCategoriesDishes)
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_CATEGORIES_HOOKAH, getCategoriesHookah)
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_KITCHEN_DISHES, getKitchenDishes)
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_TAGS_DISHES, getTagsDishes)
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_STOCKS_FILTER, getStocksByFilter)
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_SIMILAR, getSimilar) 
  yield takeEvery(constants.RESTAURANTS_SAGA_GET_TASKS_CURRENT, getTasksRestaurant)
  yield takeEvery(constants.RESTAURANTS_SAGA_SEARCH_BY_LIKE, searchLikeRestaurants)
}

export default function* RestaurantsSagas() {
  yield fork(watchRestaurants)
}