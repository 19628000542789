import { put, call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/hashtags.action";
import * as constants from "domain/constants/hashtags.constant";
import * as types from "domain/types/hashtags.type";
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';

const api = new ApiController();
const norr = new NorrController();

export async function getStocksFetch(): Promise<Result<Array<types.IHashtag>>> {
  return await api.get(`/hashtags`)
}

export function* getHashtags(): any{
  yield put(actions.request(true))

  const response = yield call(getStocksFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setHashtags(response.value))
  })

  yield put(actions.request(false))
}

export function* watchStocks() {
  yield takeEvery(constants.HASHTAGS_SAGA_GET_ALL, getHashtags)
}

export default function* StocksSagas() {
  yield fork(watchStocks)
}