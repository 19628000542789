import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import styles from '../styles.module.css';
import { IReview } from 'domain/types/reviews.type';
import { Rating } from "primereact/rating";
import { toDateMonth } from 'utils/toDate.util';
import { Button } from 'primereact/button';
import { isHTTPS } from 'utils/regexps';

interface IProps {
  data: IReview[];
}

interface IPropsItem {
  item: IReview,
  isEdit?: boolean,
  onShow?: (item: IReview) => void
}

export const ReviewItemGallery: React.FC<IPropsItem> = (props: IPropsItem) => {
  const { item, isEdit = false, onShow = () => {} } = props;
  const accountName = item.account.name.length ? item.account.name : null;
  const accountSurname = item.account.surname ? item.account.surname : ""
  const fio = accountName ? `${accountName} ${accountSurname}` : "Анонимный пользователь(Имя не указано)"
  
  return (
    <>
      <div className="p-d-flex p-jc-between p-col-12 p-mt-3 p-mb-1">
        <div className="p-d-flex">
          <img 
            src={`${isHTTPS(item.account?.avatar) ? item.account.avatar : '/api/files/' + item.account?.avatar}`}
            alt={`avatar`} 
            className={styles['review_avatar']}
            onError={(e: any) => e.target.src = `${process.env.PUBLIC_URL}/images/avatar_default.png` }
          />
          <div className="p-ml-2">
            <h5 className={styles['review_manager']}>{fio}</h5>
                  
            <span className={styles['review_manager_text']}>
              Отзыв написан {toDateMonth(item.createdAt)}
            </span>
          </div>
        </div>

        {isEdit && (
          <span 
            className="task-badge status-start"
            style={{color: item.publish ? "#256029" : "#c63737" }}
          >{item.publish ? "Опубликовано" : "Не опубликовано"}</span>
        )}
      </div>

      <Rating cancel={false} stars={5} disabled value={item.balls} className={styles['rating']}/>
      <p className={styles['review_text'] + " p-mt-0 p-mb-4 p-px-2"}>{item.review}</p>

      {(isEdit && item?.rejects.length) ? (
        <i style={{fontSize: '12px', color: 'red', display: 'block' }}>Комментарий модератора: {item.rejects[0].comment}</i>
      ) : <></>}

      {(isEdit && !item.publish) && (
        <Button 
          label="Редактировать" 
          className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-outlined" 
          onClick={()=> onShow(item)} 
        />
      )}
    </>
  )
}

const ReviewsGalleria: React.FC<IProps> = (props: IProps) => {
  const { data } = props;

  let slidesPerView = 
    window.innerWidth > 1200 ? 3 : 
    window.innerWidth <= 768 ? 1 : 2;

  let _data = data.map((item: IReview, index: React.Key | null | undefined) => {
    return (
      <SwiperSlide key={index} className={styles['swiper_slide'] + ' p-px-2'} >
        <ReviewItemGallery item={item} />
      </SwiperSlide>
    );
  });  

  return (
    <>
      <Swiper
        // loop={true}
        slidesPerView={slidesPerView}
        spaceBetween={5}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className={styles['swiper']}
      >
        {_data}
      </Swiper>
    </>
  );
};

export default ReviewsGalleria;


