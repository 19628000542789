import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBookOpen,
  faBorderNone,
  faMapMarkerAlt,
  faPhoneAlt,
  faReceipt,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faCog,
  faUser,
  faUsers,
  faLock,
  faEnvelope,
  faCheckCircle,
  faChartPie,
  faHamburger,
  faBars,
  faSignOutAlt,
  faCalendarAlt,
  faPen,
  faInfoCircle,
  faExclamationCircle,
  faChalkboard,
  faCreditCard,
  faRubleSign,
  faClock,
  faMoneyBill,
  faDollarSign,
  faLemon,
  faJedi,
  faWifi, 
  faTv, 
  faDrum,  
  faCar, 
  faUtensils, 
  faDice, 
  faWineGlass, 
  faCheck, 
  faSun,
  faBan,
  faTruck,
  faPersonBooth,
  faChild,
  faQuestionCircle,
  faUserCircle,
  faWalking,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
// import {
//   faInstagram,
//   faVk,
//   faFacebook,
//   faGoogle,
//   faXbox
// } from "@fortawesome/free-brands-svg-icons";
// <FontAwesomeIcon icon="fa-solid fa-person-walking" />
library.add(
  faWalking,
  faBookOpen,
  faBorderNone,
  faMapMarkerAlt,
  faPhoneAlt,
  faReceipt,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faCog,
  faChalkboard,
  faUser,
  faUsers,
  faLock,
  faEnvelope,
  faCheckCircle,
  faChartPie,
  faHamburger,
  faBars,
  faSignOutAlt,
  faCalendarAlt,
  faPen,
  faInfoCircle,
  faExclamationCircle,
  // faInstagram,
  // faVk,
  // faFacebook,
  // faGoogle,
  faCreditCard,
  faRubleSign,
  faClock,
  faMoneyBill,
  faDollarSign,
  // faXbox,
  faLemon,
  faJedi,
  faWifi, 
  faTv, 
  faDrum,  
  faCar, 
  faUtensils, 
  faDice, 
  faWineGlass, 
  faCheck, 
  faSun,
  faBan,
  faTruck,
  faPersonBooth,
  faChild,
  faQuestionCircle,
  faUserCircle,
  faWindowClose
);