import React from "react";
import { connect } from "react-redux";
import { showWindow } from "domain/actions/auth.action";

import styles from "../style.module.css";

export const SignIn: React.FC = (props: any) => {
  const login = () => props.showWindow('auth', true);
  const register = () => props.showWindow('register', true);

  return (
    <div className="mobile-menu-auth p-col-12 p-p-0 p-d-flex p-flex-column">
      <img 
        className={styles.mobile_menu_avatar}
        src={`${process.env.PUBLIC_URL}/images/avatar_default.png`}
        alt=""
      />
      <div 
        className={styles.mobile_menu_value + " p-pt-3 p-pl-2"}
        
      >
        <span onClick={login} className="cursor-pointer">Войти</span> или <span onClick={register} className="cursor-pointer">зарегистрироваться</span>
      </div>
    </div>
  );
}

export default connect(null, {showWindow})(SignIn);
