import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/review.action";
import * as constants from "domain/constants/reviews.constant";
import * as types from "domain/types/reviews.type";
import { Result } from 'domain/types/other.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { getAccountReviews } from './account.saga';

const api = new ApiController();
const norr = new NorrController();

export async function getReviewsFetch(): Promise<Result<types.IReview[]>> {
  return await api.get(`/3raza/review`)
}

export async function postReviewFetch(data: types.IReview): Promise<null | undefined> {
  return await api.post(`/3raza/review`, data)
}

export async function putReviewFetch(data: types.IReview): Promise<null | undefined> {
  return await api.put(`/3raza/review/${data.id}`, data)
}

export function* getReviews(): any{
  yield put(actions.reqReviews(true))

  const response = yield call(getReviewsFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setReviews(response.value))
  })

  yield put(actions.reqReviews(false))
}

export function* postReview(action: any): any{
  yield put(actions.reqReviews(true))
  const data = action.payload.review;
  const cb = action.payload.cb;

  const response = yield call(postReviewFetch, data)

  yield call(norr.processing, response, function *(){
    yield call(norr.success, 'Отзыв отправлен на модерацию')
    cb();
  })
    
  yield put(actions.reqReviews(false))
}

export function* putReview(action: any): any{
  yield put(actions.reqReviews(true))
  const data = action.payload.review;
  const cb = action.payload.cb;

  const response = yield call(putReviewFetch, {...data, balls: Number(data.balls)})

  yield call(norr.processing, response, function *(){
    yield call(norr.success, 'Отзыв отправлен на модерацию')
    yield call(getAccountReviews)
    cb();
  })
    
  yield put(actions.reqReviews(false))
}

export function* watchStocks() {
  yield takeEvery(constants.REVIEWS_SAGA_GET_ALL, getReviews)
  yield takeEvery(constants.REVIEWS_SAGA_POST, postReview)
  yield takeEvery(constants.REVIEWS_SAGA_PUT, putReview)
}

export default function* StocksSagas() {
  yield fork(watchStocks)
}