import React from "react";
import { connect } from "react-redux";
import { getCityById, getCityByCoord } from "domain/actions/city.action";

import GeoBtn from "./geoBtn";
import { Search } from "components";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import styles from "../style.module.css";
import { ICity } from "domain/types/city.type";
import { setSorts } from "domain/actions/restaurant.action";

interface IComponentProps{
  show: boolean;
  toggleShow: (boolean: boolean) => void;
}

const GeoWindow: React.FC<IComponentProps> = (props: any) => {
  const { cities, fasting, getCityById, getCityByCoord, show, toggleShow } = props;
  
  const select = (id: string) => {
    getCityById(id);
    toggleShow(false);
  }

  const handleGeoBtnClick = () => {
    getCityByCoord();
    toggleShow(false);
  }

  let renderItem = fasting?.map((city: ICity) => {
    return (
      <span
        key={city.id}
        onClick={() => select(city.id)}
      >
        {city.name}
      </span>
    );
  });

  return (
    <Dialog
      className={`${styles.dialog} p-m-0 p-col-12 p-m-md-3 p-md-6 p-p-0 `}
      header="Выберите город"
      visible={show}
      position={"top-left"}
      modal
      draggable = {false}
      onHide={() => toggleShow(false)}
    >
      <div className="p-d-flex p-flex-column">
        <div className={`p-d-flex p-flex-wrap ${styles.list}`}>
          {renderItem}
        </div>

        <Search
          data={cities}
          onSelect={select}
        />

        <Divider align="center" type="dashed"> <b>или</b> </Divider>

        <GeoBtn onClick={handleGeoBtnClick} />
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  fasting: state.cities.fasting,
  cities: state.cities.cities,
});

export default connect(mapStateToProps, { getCityById, getCityByCoord, setSorts })(GeoWindow);